import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
// Container
import Container from "./styled";
// Iconos
import { BsWhatsapp } from "react-icons/bs";
// Librerias
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import "tippy.js/animations/scale-subtle.css";
import "tippy.js/animations/scale-extreme.css";
// Animaciones
import Animacion from "./animacion";
import AnimacionCarga from "./animacion-carga";
// Custom Hooks
import { useCotizador } from "../../hooks/useCotizador";
import { useForm } from "../../hooks/useForm";
// Form
import { cotizadorReglas } from "../../utils/formRules";
import { cotizadorForm } from "../../utils/formInitialValues";
// Params
import { PARAMS } from "../../constants/params";
// Servicios
import { postearOperacion, postearCotizacion } from "../../services/cotizador";

export default function Cotizador({ subtitle, landingVehiculo, isReferidosHeaderOpen }) {

  // Estado para el loading
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Params provenientes de la URL
  const [ searchParams ] = useSearchParams();
  const location = useLocation()

  // Custom Hooks
  const { inputValues, handleChange, handleValidation, formErrors } = useForm(handleSubmit, cotizadorReglas, cotizadorForm)
  const { provincias, modelos, marcas, localidades, ip, canal_url } = useCotizador(inputValues)

  const pathname = location.pathname
  const canal = pathname === "/asap-consulting" ? 179 : canal_url

  // Enviar mail
  async function handleSubmit () {
    setLoading(true);
    const origen = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ? "mobile" : "web";

    const urlParams = {}
    PARAMS.forEach(param => urlParams[param] = searchParams.get(param))

    const { telefono, codigoArea, vehiculo, ...cotizadorValues } = inputValues

    const dataOperacion = {
      ...cotizadorValues,
      telefono: `(${codigoArea}) ${telefono}`,
      producto: vehiculo,
      ip_adress: ip,
      autogestion: 0,
      origen: origen,
      canal_url: canal,
      ...urlParams
    };
 
    try {
      const postOperacion = await postearOperacion(dataOperacion)
      const cotizacionTablaData = {
        id_detalle_prod_motos: postOperacion.data.id,
        id_operacion: postOperacion.data.numero,
      }
      const postCotizacion = await postearCotizacion(cotizacionTablaData)
      if (postOperacion.status === 200 && postCotizacion.status === 200) {
        navigate(`/pasos/?op=${postOperacion.data.numero}`, { state: {...postOperacion.data } })
      }  
    } catch (error) {
      console.log(error);
    } 

  };

  return (
    <Container name="cotizador" isReferidosHeaderOpen={isReferidosHeaderOpen}>
      {/* Formulario Container */}
      <div id="form-titulo-container" className="opacidad05">
        {/* Titulos */}
        <div id="titulos-logo-container">
          {pathname === "/asap-consulting" ?
            <div className="empresa-logo-container">
              <img src="/logos/asap_consulting.svg" alt="Logo de ASAP Consulting"></img>
            </div>
          : null}
          <div id="titulos-container">
            {/* Titulo */}
            <h1 className="opacidad05">¡Cotizá tu seguro!</h1>
            <h3 className="opacidad05">
              Ella te acompaña a donde vayas, vos elegí el camino que los une.
            </h3>
          </div>
        </div>
        {/* Formulario Container */}
        <div id="form-container" className="opacidad05">
          {/* Formulario */}
          <form>
            {/* Inputs Container */}
            <div id="inputs-container">
              {/* Input Nombre */}
              <div className="input-container" id="input-nombre-container">
                <input
                  type="text"
                  id="input-nombre"
                  name="nombre"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.nombre}
                  className={formErrors.nombre == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.nombre == null || inputValues.nombre == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-nombre"
                >
                  Nombre
                </label>
              </div>
              {/* Input Apellido */}
              <div className="input-container" id="input-apellido-container">
                <input
                  type="text"
                  id="input-apellido"
                  name="apellido"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.apellido}
                  className={formErrors.apellido == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.apellido == null || inputValues.apellido == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-apellido"
                >
                  Apellido
                </label>
              </div>
              {/* Input Email */}
              <div className="input-container" id="input-email-container">
                <input
                  type="email"
                  id="input-email"
                  name="email"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.email}
                  className={formErrors.email == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.email == null || inputValues.email == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-email"
                >
                  Email
                </label>
              </div>
              {/* Input Codigo de Area */}
              <div className="input-container" id="input-codigo-area-container">
                <input
                  type="number"
                  name="codigoArea"
                  id="input-codigo-area"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.codigoArea}
                  className={formErrors.codigoArea == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.codigoArea == null ||
                    inputValues.codigoArea == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-codigo-area"
                >
                  Cod. Área
                </label>
              </div>
              {/* Input Telefono */}
              <div className="input-container" id="input-telefono-container">
                <input
                  type="number"
                  id="input-telefono"
                  name="telefono"
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  value={inputValues.telefono}
                  className={formErrors.telefono == null ? "" : "input-error"}
                ></input>
                <label
                  className={
                    inputValues.telefono == null || inputValues.telefono == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="input-telefono"
                >
                  Teléfono
                </label>
              </div>
              {/* Provincia*/}
              <div className="select-container" id="select-provincia-container">
                <select
                  id="select-provincia"
                  name="provincia"
                  value={inputValues.provincia}
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  className={formErrors.provincia == null ? "" : "input-error"}
                >
                  <option disabled></option>
                  {provincias.sort().map((provincia) => {
                    return <option value={provincia}>{provincia}</option>;
                  })}
                </select>
                <label
                  className={
                    inputValues.provincia == null || inputValues.provincia == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="select-provincia"
                >
                  Provincia
                </label>
                <div className="flecha-select-container">
                  <i></i>
                </div>
              </div>
              {/* Localidad */}
              <div className="select-container" id="select-localidad-container">
                <select
                  id="select-localidad"
                  name="localidad"
                  value={inputValues.localidad}
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  className={formErrors.localidad == null ? "" : "input-error"}
                >
                  <option disabled></option>
                  {localidades.map((localidad) => {
                    return (
                      <option value={localidad}>
                        {localidad.charAt(0).toUpperCase() +
                          localidad.slice(1).toLowerCase()}
                      </option>
                    );
                  })}
                </select>
                <label
                  className={
                    inputValues.localidad == null || inputValues.localidad == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="select-localidad"
                >
                  Localidad
                </label>
                <div className="flecha-select-container">
                  <i></i>
                </div>
              </div>
              {/* Vehiculo */}
              <div className="select-container" id="select-vehiculo-container">
                <select
                  id="select-vehiculo"
                  name="vehiculo"
                  value={inputValues.vehiculo}
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  className={formErrors.vehiculo == null ? "" : "input-error"}
                >
                  <option disabled></option>
                  <option value="Motocicleta">Motocicleta</option>
                  <option value="Motocicleta (Uso comercial)">
                    Motocicleta (Uso comercial)
                  </option>
                  <option value="Cuatriciclo">Cuatriciclo</option>
                  <option value="Scooter">Scooter</option>
                  <option value="Ciclomotor">Ciclomotor</option>
                </select>
                <label
                  className={
                    inputValues.vehiculo == null || inputValues.vehiculo == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="select-vehiculo"
                >
                  Vehiculo
                </label>
                <div className="flecha-select-container">
                  <i></i>
                </div>
              </div>
              {/* Marca */}
              <div className="select-container" id="select-marca-container">
                <select
                  id="select-marca"
                  name="marca"
                  value={inputValues.marca}
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  className={formErrors.marca == null ? "" : "input-error"}
                >
                  <option disabled></option>
                  {marcas.map((marca) => {
                    return (
                      <option value={marca.name}>
                        {marca.charAt(0).toUpperCase() +
                          marca.slice(1).toLowerCase()}
                      </option>
                    );
                  })}
                </select>
                <label
                  className={
                    inputValues.marca == null || inputValues.marca == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="select-marca"
                >
                  Marca
                </label>
                <div className="flecha-select-container">
                  <i></i>
                </div>
              </div>
              {/* Modelo */}
              <div className="select-container" id="select-modelo-container">
                <select
                  id="select-modelo"
                  name="modelo"
                  value={inputValues.modelo}
                  onChange={handleChange}
                  onFocus={handleChange}
                  onBlur={handleChange}
                  className={formErrors.modelo == null ? "" : "input-error"}
                >
                  <option disabled></option>
                  {modelos.map((modelo) => {
                    return (
                      <option value={modelo.id}>
                        {modelo.modelo.charAt(0).toUpperCase() +
                          modelo.modelo.slice(1).toLowerCase()}
                      </option>
                    );
                  })}
                </select>
                <label
                  className={
                    inputValues.modelo == null || inputValues.modelo == ""
                      ? "label-down"
                      : "label-top"
                  }
                  htmlFor="select-modelo"
                >
                  Modelo
                </label>
                <div className="flecha-select-container">
                  <i></i>
                </div>
              </div>
              {/* Antigüedad */}
                <div
                  className="input-container"
                  id="input-antiguedad-container"
                >
                  <input
                    type="number"
                    id="input-antiguedad"
                    name="antiguedad"
                    onChange={handleChange}
                    onFocus={handleChange}
                    onBlur={handleChange}
                    value={inputValues.antiguedad}
                    className={
                      formErrors.antiguedad == null ? "" : "input-error"
                    }
                  ></input>
                  <label
                    className={
                      inputValues.antiguedad == null ||
                      inputValues.antiguedad == ""
                        ? "label-down"
                        : "label-top"
                    }
                    htmlFor="input-antiguedad"
                  >
                    Antiguedad
                  </label>
                </div>
            </div>
            {/* Botones Container */}
            <div id="botones-container">
              {/* Whatsapp Button */}
              <Tippy content="Días hábiles de 9 a 18hs" animation="scale">
                <a
                  href="https://api.whatsapp.com/send?phone=5491162255399&text=%C2%A1Hola%2C%20necesito%20ayuda%20para%20cotizar%20una%20moto!%20Ingresé%20desde%20la%20web"
                  target="_blank"
                >
                  <span>
                    <BsWhatsapp />
                  </span>
                  Necesito ayuda
                </a>
              </Tippy>
              {/* Cotizar Button */}
              <button
                type="submit"
                onClick={handleValidation}
                className="submit-button"
              >
                Cotizá tu seguro
                <img
                  alt="Ícono vehículo"
                  src={
                    process.env.PUBLIC_URL +
                    "/home/cotizador/vehiculos/motocicleta.svg"
                  }
                ></img>
              </button>
            </div>
            {/* Loading Container */}
            {loading ? (
              <div id="loading-container" className="opacidad03">
                <div id="animacion-loading">
                  <AnimacionCarga></AnimacionCarga>
                </div>
              </div>
            ) : (
              <></>
            )}
          </form>
        </div>
      </div>
      {/* Animacion Container */}
      <div id="animacion-container" className="opacidad08">
        {/* Animacion */}
        <Animacion></Animacion>
      </div>
    </Container>
  );
}
