import React, { Component } from "react";
import Lottie from "react-lottie";
import * as animationData from "./animation.json";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1 !important;

  div {
    width: 93% !important;
    height: 91% !important;
  }
`;

export default class Animacion extends Component {
  render() {
    const animationOptions = {
      loop: true,
      autoplay: true,
      renderer: "canvas",
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <Container>
        <Lottie options={animationOptions} height={535} width={792} />
      </Container>
    );
  }
}
