import { useState, useEffect } from "react";
import axios from "axios"
import { getProvincias, getLocalidades} from "../services/ubicacion";
import marcas from "../constants/marcas.json"
import marcasModelos from "../constants/modelos.json"

export const useCotizador = (inputValues) => {

  const [provincias, setProvincias] = useState([])
  const [localidades, setLocalidades] = useState([])
  const [modelos, setModelos] = useState([])
  const [ip, setIp] = useState("")
  const [canal_url, setCanal_url] = useState("")

  // Obtiene la IP del usuario
  useEffect(() => {
    const data = async () => {
      const res = await axios.get("https://geolocation-db.com/json/");
      setIp(res.data.IPv4);
      // Obtiene la ruta desde la que se ingresá (canales)
      const url = window.location.toString().split("/");
      const sufijo = url[url.length - 1];
      let canal;
      switch (sufijo) {
        case "gs":
          canal = 1;
          break;
        case "gd":
          canal = 2;
          break;
        case "in":
          canal = 38;
          break;
        case "fb":
          canal = 7;
          break;
        case "tiktok":
          canal = 135;
          break;
        case "twitch":
          canal = 141;
          break;
        default:
          canal = 24;
          break;
      }
      setCanal_url(canal);
    };
    data();
  }, []);

  // Obtener provincias
  useEffect(() => {
    const fetchProvincias = async () => {
      try {
        const provincias = await getProvincias()
        setProvincias(provincias.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchProvincias();
  }, []);

  // Obtener localidades
  useEffect(() => {
    const fetchLocalidades = async () => {
      try {
        setLocalidades([])
        const localidades = await getLocalidades(inputValues.provincia)
        setLocalidades(localidades.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchLocalidades();
  }, [inputValues.provincia]);

  // Obtener modelos
  useEffect(() => {
    const fetchModelos = async () => {
      try {
        setModelos([])
        const modelosByMarca = marcasModelos.filter(modelo => (modelo.marca.toLowerCase() === inputValues.marca.toLowerCase() && modelo.producto === inputValues.vehiculo))
        setModelos(modelosByMarca);
      } catch (error) {
        console.log(error);
      }
    };
    fetchModelos();
  }, [inputValues.marca, inputValues.vehiculo]);

  return {
    provincias,
    localidades,
    marcas,
    modelos,
    ip,
    canal_url
  }
}