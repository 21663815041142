import React, { useEffect, useState } from "react";
import styled from "styled-components";
// Components
import Header from "../components/header/index";
import Cotizador from "../components/cotizador/index";
import Logos from "../components/home/logos";
import Cubrimos from "../components/home/cubrimos";
import Pasos from "../components/home/pasos";
import Referidos from "../components/home/referidos";
import Carousel from "../components/home/carousel";
import Beneficios from "../components/home/beneficios";
import Comunicacion from "../components/home/comunicacion";
import Faqs from "../components/home/faqs";
import Footer from "../components/footer";
// Librerias
import Headroom from "react-headroom";
import BotonFlotante from "../components/home/boton-flotante";
import { useSEO } from "../hooks/useSEO";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  background-color: var(--blanco);
  overflow: hidden;
`;

export default function HomeContainer({ data }) {
  // Scroll ni bien carga la página
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  // Referidos Header
  const [isReferidosHeaderOpen, setReferidosHeaderOpen] = useState(true);
  const toggleReferidosHeader = () => {
    setReferidosHeaderOpen(!isReferidosHeaderOpen);
  };

  useSEO({
    title: "SeguroMotos: Cotizá tu Seguro de Moto",
    description: "¡Cotizá tu seguro online! Granizo, robo desde 2014, todo riesgo.",
  })

  return (
    <Container>

      {/* Boton Flotante WhatsApp*/}
      <BotonFlotante />

      {/* Canonical */}
      <head>
        <link rel="canonical" href="https://seguromotos.com.ar" />
      </head>
      {/* Header */}
      <Headroom>
        <Header
          isReferidosHeaderOpen={isReferidosHeaderOpen}
          toggleReferidosHeader={toggleReferidosHeader}
        ></Header>
      </Headroom>
      {/* Cotizador */}
      <Cotizador isReferidosHeaderOpen={isReferidosHeaderOpen}></Cotizador>
      {/* Logos */}
      <Logos></Logos>
      {/* Cubrimos */}
      <Cubrimos></Cubrimos>
      {/* Pasos */}
      <Pasos></Pasos>
      {/* Referidos */}
      <Referidos></Referidos>
      {/* Carousel */}
      <Carousel></Carousel>
      {/* Beneficios */}
      <Beneficios></Beneficios>
      {/* Comunicacion */}
      <Comunicacion></Comunicacion>
      {/* FAQs */}
      <Faqs></Faqs>
      {/* Footer */}
      <Footer marcas="active"></Footer>
    </Container>
  );
}
