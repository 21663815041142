import styled from "styled-components";
import { breakpoints } from "../../constants/media";

const Container = styled.div`
  width: 1500px;
  margin: ${(props) =>
    props.isReferidosHeaderOpen == true
      ? "168px 0px 0px 0px;"
      : "116px 0px 0px 0px"};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;

  // Formulario Titulo Container
  #form-titulo-container {
    width: 740px;
    margin: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    z-index: 60;
  }

  // Titulos Logo Container
  #titulos-logo-container {
    width: 100%;
    margin: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
  }

  // Titulos Container
  #titulos-container {
    width: 100%;
    margin: 0px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Titulos
  h1,
  h3 {
    width: 100% !important;
    margin: 0px 0px 8px 0px;
    color: var(--negro);
    letter-spacing: -0.015em;
  }

  h3 {
    margin: 0px 0px 0px 0px;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
  }

  // Formulario Container
  #form-container {
    width: 100%;
    margin: 56px 0px 0px 0px;
    padding: 32px 32px 32px 32px;
    display: flex;
    justify-content: center;
    position: relative;
    background-color: var(--blanco);
    border-radius: 12px;
    /*
    box-shadow: 0px 5px 30px var(--sombraNaranja);
    border: 1px solid var(--grisClaro);
    */
    box-shadow: 0px 5px 30px #0034fd45;
    border: 1px solid #0034fd33;
  }

  // Formulario
  form {
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }


  // Inputs Container
  #inputs-container {
    width: 100%;
    margin: 4px 0px 0px 0px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
  }

  // Input Container
  #inputs-container .input-container,
  #inputs-container .select-container {
    width: 48%;
    margin: 0px 0px 24px 0px;
    position: relative;
  }

  // Input, Select and Label
  #inputs-container .input-container input,
  #inputs-container .input-container label,
  #inputs-container .select-container select {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    z-index: 58 !important;
  }

  // Select Label
  #inputs-container .select-container label {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }

  // Inputs and Select
  #inputs-container .input-container input,
  #inputs-container .select-container select {
    width: 100%;
    height: 35px;
    padding: 0px 16px 0px 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75em;
    //color: var(--gris);
    color: #595959;
    border: 1px solid rgb(152, 152, 154, 0.3);
    background-color: #fefefe;
    -webkit-appearance: none;
    box-shadow: 0;
    transition: 0.2s all;
    border-radius: 6px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  // Label
  #inputs-container .input-container label,
  #inputs-container .select-container label {
    position: absolute;
    top: 0.95em;
    left: 0;
    margin: 0px 0px 0px 16px;
    line-height: 1;
    font-size: 0.75em;
    font-weight: 400;
    //color: rgb(152, 152, 154, 0.6);
    color: #595959;
    cursor: text;
    z-index: 60 !important;
  }

  // Input and Select On Focus Label
  #inputs-container .input-container input:focus + label,
  #inputs-container .select-container select:focus + label {
    top: -0.6em;
    background-color: var(--blanco);
    padding: 0px 6px 0px 6px;
    margin: 0px 0px 0px 11px;
    font-weight: 500;
    font-size: 0.7em;
    color: var(--naranja100) !important;
    z-index: 80 !important;
  }

  // Inputs and Select Focus
  #inputs-container .input-container input:focus,
  #inputs-container .select-container select:focus {
    border: 1px solid var(--naranja100);
    outline: none;
    z-index: 59 !important;
    box-shadow: 0px 0px 5px var(--sombraNaranja);
  }

  // Label Down
  .label-down {
    z-index: 57 !important;
  }

  // Label Top
  .label-top {
    top: -0.6em !important;
    background-color: var(--blanco);
    padding: 0px 6px 0px 6px;
    margin: 0px 0px 0px 11px !important;
    font-weight: 500 !important;
    font-size: 0.7em !important;
    color: #041726 !important;
    z-index: 80 !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
    */

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield !important;
  }

  // Select Container
  .select-container {
    position: relative;
    height: 35px;
    width: 100%;
    max-width: 330px;
    margin: 0px 0px 24px 0px !important;
  }

  // Select
  .select-container select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    display: inline-block;
    width: 100%;
    padding: 0px 16px 0px 16px;
    margin: 0px 0px 0px 0px;
    height: 100%;
    cursor: pointer;
    outline: 0;
    border: 1px solid rgb(152, 152, 154, 0.6);
    font-style: normal;
    font-weight: normal;
    color: var(--gris);
    position: relative;
    transition: all 0.25s ease;
    z-index: 60;
    background-color: transparent;
    border-radius: 0;
    -webkit-appearance: none;
    box-shadow: 0;
    border-radius: 3px;
    font-size: 0.75em;
    text-transform: capitalize !important;
  }

  // Select Flecha Container
  .flecha-select-container {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 16px;
    top: calc(50% - 10px);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    background-color: var(--blanco);
    z-index: 59;
    transition: 0.2s all;
  }

  // Select Arrow
  .select-container i {
    width: 7px;
    height: 7px;
    margin: -4px 0px 0px 0px;
    display: block;
    border-left: 3px solid rgb(152, 152, 154, 0.6);
    border-bottom: 3px solid rgb(152, 152, 154, 0.6);
    transform: rotate(-45deg);
    border-radius: 10%;
    transition: 0.3s all;
  }

  #inputs-container
    .select-container
    select:focus
    ~ .flecha-select-container
    i {
    margin: 3px 0px 0px 0px !important;
    transform: rotate(135deg) !important;
  }

  // Select default arrow
  .select-container select::-ms-expand {
    display: none;
  }

  // Select Option
  .select-container select * {
    color: var(--negro);
  }

  #input-nombre-container,
  #input-apellido-container,
  #select-modelo-container,
  #select-antiguedad-container,
  #input-antiguedad-container,
  #input-cilindrada-container {
    width: 160.5px !important;
    max-width: 160.5px !important;
  }

  #input-nombre-container input,
  #input-apellido-container input {
    text-transform: capitalize !important;
  }

  #input-email-container,
  #select-provincia-container,
  #select-localidad-container,
  #select-vehiculo-container,
  #select-marca-container {
    width: 330px !important;
    max-width: 330px !important;
  }

  #input-codigo-area-container {
    width: 101px !important;
    max-width: 101px !important;
  }

  #input-telefono-container {
    width: 220px !important;
    max-width: 220px !important;
  }

  // Input Error
  .input-error {
    border: 1px solid red !important;
  }

  // Botones Container
  #botones-container {
    width: 100%;
    margin: 24px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }

  #botones-container a {
    background-color: var(--blanco);
    color: var(--verde50);
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
    transition: 0.2s;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    text-decoration: none;
  }

  #botones-container a span {
    font-size: 17px;
    margin: 4px 8px 0px 0px;
  }

  #botones-container button {
    padding: 7px 24px 7px 24px;
    border-radius: 8px;
    border: 0px solid;
    color: var(--blanco);
    background-color: var(--naranja100);
    cursor: pointer !important;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  #botones-container button img {
    height: 17px;
    margin-left: 10px;
    transition: 0.4s all;
  }

  #botones-container button:hover {
    background-color: var(--naranja40);
  }

  #botones-container button:hover > img {
    transform: translate(10px);
  }

  // Animacion Container
  #animacion-container {
    width: calc(100% - 830px);
    height: 586.33px;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    z-index: 40;
  }

  // Bicicleteria Logo Container
  .empresa-logo-container {
    max-width: 98px;
    max-height: 150px;
    margin: 0px 24px 0px 0px;
    padding: 16px 16px 16px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: var(--grisClaro2);
    border-radius: 16px;
  }

  // Bicicleteria Logo Container Image
  .empresa-logo-container img {
    display: block;
    width: 100%;
    object-fit: cover;
  }

  // Loading Container
  #loading-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: var(--blanco);
    border-radius: 12px;
    z-index: 66;
    animation-name: animation-loading;
    animation-duration: 0.1s;
    animation-iteration-count: initial;
    animation-timing-function: ease-out;
  }

  // Animación Entrada Loading
  @keyframes animation-loading {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  // Animación Loading
  #animacion-loading {
    width: 250px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    width: 1000px;
    position: relative;

    // Formulario Container
    #form-container {
      width: 480px;
      padding: 25px 25px 25px 25px;
    }

    // Inputs y Selects
    #input-nombre-container,
    #input-apellido-container,
    #input-email-container,
    #input-antiguedad-container,
    #select-provincia-container,
    #select-localidad-container,
    #select-vehiculo-container,
    #select-marca-container,
    #select-modelo-container,
    #select-antiguedad-container {
      width: 48.5% !important;
      max-width: 48.5% !important;
    }

    #input-codigo-area-container {
      width: 20% !important;
      max-width: 20% !important;
    }

    #input-telefono-container {
      width: 26% !important;
      max-width: 26% !important;
    }

    // Animacion Container
    #animacion-container {
      width: calc(100% - 400px);
      height: 523px;
      position: absolute;
      top: 60px;
      right: -50px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    width: 680px;
    justify-content: center;

    #titulos-logo-container {
      justify-content: center;
      align-items: center;
    }

    // Bicicleteria Logo Container
    .empresa-logo-container {
      margin: 0px 0px 0px 0px;
    }

    // Titulos
    h1,
    h3 {
      text-align: center;
    }

    // Formulario Container
    #form-container {
      width: 100%;
    }

    // Inputs y Selects
    #input-nombre-container,
    #input-apellido-container,
    #input-antiguedad-container,
    #select-modelo-container,
    #select-antiguedad-container {
      width: 147px !important;
      max-width: 147px !important;
    }

    #input-email-container,
    #select-provincia-container,
    #select-localidad-container,
    #select-vehiculo-container,
    #select-marca-container {
      width: 308px !important;
      max-width: 308px !important;
    }

    #input-codigo-area-container {
      width: 101px !important;
      max-width: 101px !important;
    }

    #input-telefono-container {
      width: 193px !important;
      max-width: 193px !important;
    }

    // Animacion Container
    #animacion-container {
      display: none;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    width: 480px;

    // Inputs y Selects
    #input-nombre-container,
    #input-apellido-container,
    #input-antiguedad-container,
    #select-marca-container,
    #select-modelo-container,
    #select-antiguedad-container,
    #input-cilindrada-container,
    #input-email-container,
    #select-provincia-container,
    #select-localidad-container,
    #select-vehiculo-container,
    #input-codigo-area-container,
    #input-telefono-container {
      width: 208px !important;
      max-width: 208px !important;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    width: 350px;

    #oferta-container div:nth-child(2) {
      width: 65%;
    }

    #oferta-container div:nth-child(2) p:nth-child(2) {
      line-height: 17px;
    }

    // Inputs y Selects
    #input-nombre-container,
    #input-apellido-container,
    #input-antiguedad-container,
    #select-marca-container,
    #select-modelo-container,
    #select-antiguedad-container,
    #input-cilindrada-container,
    #input-email-container,
    #select-provincia-container,
    #select-localidad-container,
    #select-vehiculo-container,
    #input-codigo-area-container,
    #input-telefono-container {
      width: 142px !important;
      max-width: 142px !important;
    }

    #botones-container {
      flex-direction: column-reverse;
    }

    #botones-container a {
      width: 100%;
      margin: 20px 0px 0px 0px;
      justify-content: center;
    }

    #botones-container button {
      width: 100%;
      padding: 12px 27px 12px 27px;
    }
  }

  // 414px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile414}) {
    width: 85%;
    margin: 15px 0px 0px 0px;

    // Titulos
    h1 {
      font-size: 30px;
    }

    h3 {
      font-size: 17px;
    }

    // Formulario Container
    #form-container {
      margin: 30px 0px 0px 0px;
    }

    // Oferta Container
    #oferta-container div:first-child {
      display: none;
    }

    #oferta-container div:nth-child(2) {
      width: 100%;
      max-width: 198px;
    }

    // Inputs y Selects
    #input-nombre-container,
    #input-apellido-container,
    #input-antiguedad-container,
    #select-marca-container,
    #select-modelo-container,
    #select-antiguedad-container,
    #input-cilindrada-container,
    #input-email-container,
    #select-provincia-container,
    #select-localidad-container,
    #select-vehiculo-container,
    #input-codigo-area-container,
    #input-telefono-container {
      width: 47.5% !important;
      max-width: 47.5% !important;
    }
  }

  // 350px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile350}) {
    width: 85%;

    // Inputs y Selects
    #input-nombre-container,
    #input-apellido-container,
    #input-antiguedad-container,
    #select-marca-container,
    #select-modelo-container,
    #select-antiguedad-container,
    #input-cilindrada-container,
    #input-email-container,
    #select-provincia-container,
    #select-localidad-container,
    #select-vehiculo-container,
    #input-codigo-area-container,
    #input-telefono-container {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
`;

export default Container;
