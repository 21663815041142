import { useEffect, useState } from "react";
import { getCotizacionRUS } from "../services/cotizacionRUS";
import { actualizarValoresCotizacion, obtenerCotizacion, enviarMailCotizacion } from "../services/cotizaciones";
import { useLocation } from "react-router-dom";

export const usePlanes = () => {

  const location = useLocation();
  const id_operacion = new URLSearchParams(location.search).get("op");
  const hash = new URLSearchParams(location.search).get("hash");

  const locationPlanes = location?.state?.planes ? location.state.planes : []

  const [planes, setPlanes] = useState(locationPlanes)
  const [cotizacion, setCotizacion] = useState(location?.state ? location.state : {})
  const [planSelected, setPlanSelected] = useState(null)
  const [planesCotizados, setPlanesCotizados] = useState(false)

  const planesFromDb = async () => {
    try {
      const dataOp = await obtenerCotizacion(id_operacion)
      if (dataOp.status === 200) {
        setPlanes(dataOp.data.planes);
        setCotizacion(dataOp.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const updatePlanesPrices = (planes, cotizaciones) => {
    const updatedPlanes = planes.map(plan => {
      if(cotizaciones.length === 0){
        return { ...plan, cotizado: true }
      }

      const cotizacion = cotizaciones.find((cotizacion) => cotizacion.id.includes(plan.plan_api))

      if (!cotizacion) {
        console.log(plan)
        return { ...plan, cotizado: true }
      }

      return { ...plan, precio: cotizacion.premio, cotizado: true }
    })
    return updatedPlanes
  }

  const updatePlanes = (cotizaciones) => {
    setPlanes(previousPlanes => {
      const list = updatePlanesPrices(previousPlanes, cotizaciones)
      actualizarValoresCotizacion(id_operacion, list)
      return list
    })
  }

  const planesAPI = async () => {
    try {
      if (planesCotizados) return;
      const { antiguedad, modelo, modeloCodia } = location.state;

      await Promise.allSettled([
        //getCotizacionATM({ antiguedad, modelo, modeloCodia }).then(cotizacionATM => updatePlanes(cotizacionATM)),
        getCotizacionRUS({ antiguedad, modeloCodia }).then(cotizacionRUS => updatePlanes(cotizacionRUS))
      ])
      
      actualizarPlanes(true)
      await enviarMailCotizacion(id_operacion)

    } catch (error) {
      console.log(error);
    }
  }

  const handlePlan = (plan) => {
    setPlanSelected(plan)
  }

  const actualizarPlanes = (planes) => {
      setPlanesCotizados(planes)
  }

  useEffect(() => {
    if (hash) {
      planesFromDb()
    } else {
      planesAPI()
    }
  }, [])

  return {
    planes, 
    cotizacion, 
    handlePlan,
    planSelected
  }
};
