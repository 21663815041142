import React from "react";
import Animacion from "./animacion";
import { StickyContainer, Sticky } from "react-sticky";
import { Link as Boton } from "react-router-dom";
import Container from "./styled";

export default function Confirmacion() {

  return (
    <Container>
      {/* Max Width Container */}
      <div id="max-width-container">
        <div id="formulario-container">
          <h3 className="opacidad05">¡Recibimos tus datos con éxito!</h3>
          <p className="opacidad05" id="completa">
            Pronto nos pondremos en contacto para charlar más con vos.
          </p>
          <Animacion></Animacion>
        </div>
        <StickyContainer>
          <Sticky>
            {({ style }) => (
              <div id="plazos-container" style={style}>
                <p>Email</p>
                <p id="plazos-texto">rrhh@gyssrl.com.ar</p>
                <div>
                  <p>Ver más</p>
                  <Boton to="/contacto">Contacto</Boton>
                  <Boton to="/sobre-nosotros">Sobre nosotros</Boton>
                  <Boton to="/politicas-y-terminos">Políticas y términos</Boton>
                  <Boton to="/referidos">Referidos</Boton>
                </div>
              </div>
            )}
          </Sticky>
        </StickyContainer>
      </div>
    </Container>
  );
}
