import * as React from "react";
import Container from "./styled";
import { Link } from "react-scroll";
import { Fade } from "react-awesome-reveal";

export default function Beneficios() {
  return (
    <Container>
      <div id="max-width-container">
        {/* Titulo */}
        <Fade delay={100} duration={800}>
          <h3>
            <span>Beneficios</span> de asegurar con nosotros
          </h3>
        </Fade>
        {/* Beneficios Container */}
        <div id="beneficios-container">
          {/* Beneficio 1 */}
          <Fade delay={100} duration={800}>
            <div>
              <h4 id="primer-titulo">
                Asistencia<br></br>inmediata
              </h4>
              <p>
                Nuestro servicio de asistencia 24/7 te garantiza tranquilidad y
                soluciones.
              </p>
            </div>
          </Fade>
          {/* Beneficio 2 */}
          <Fade delay={100} duration={800}>
            <div>
              <h4>
                Asistencia especializada<br></br>en siniestros
              </h4>
              <p>
                Te acompañamos, asesoramos y brindamos soluciones ante un
                siniestro.
              </p>
            </div>
          </Fade>
          {/* Beneficio 3 */}
          <Fade delay={100} duration={800}>
            <div>
              <h4>Toda una gama de descuentos y coberturas</h4>
              <p>
                Aprovechá los descuentos y elegí tu forma de pago preferida, con
                tarjeta o CBU.
              </p>
            </div>
          </Fade>
        </div>
        {/* Cotizar Button */}
        <Fade delay={100} duration={800}>
          <Link
            to="cotizador"
            smooth={true}
            spy={true}
            duration={500}
            offset={-200}
          >
            ¡Cotizá tu seguro!
          </Link>
        </Fade>
      </div>
    </Container>
  );
}
