import * as React from "react";
import Container from "./styled";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Fade } from "react-awesome-reveal";

export default function CarouselBicicletas() {
  return (
    <Container>
      {/* Carousel Container */}
      <Fade delay={200} duration={800} className="fade-container">
        {/* Max Width Container*/}
        <div id="max-width-container">
          <div id="carousel-container">
            <Carousel
              showStatus={false}
              showIndicators={false}
              showThumbs={false}
              showArrows={true}
              infiniteLoop={true}
              swipeable={false}
              interval={3000}
              autoPlay
            >
              <div>
                {/* Imagen 1 */}
                <img src="/home/carousel/Honda.png" />
                <img
                  className="bici-logo"
                  src="/home/carousel/logos/honda.svg"
                />
              </div>
              <div>
                {/* Imagen 2 */}
                <img src="/home/carousel/BMW.png" />
                <img
                  className="bici-logo"
                  id="bmw-logo"
                  src="/home/carousel/logos/bmw.svg"
                />
              </div>
              <div>
                {/* Imagen 3 */}
                <img src="/home/carousel/harley.png" />
                <img
                  className="bici-logo"
                  id="harley-logo"
                  src="/home/carousel/logos/harley.svg"
                />
              </div>
              <div>
                {/* Imagen 4 */}
                <img src="/home/carousel/yamaha.png" />
                <img
                  className="bici-logo"
                  src="/home/carousel/logos/yamaha.svg"
                />
              </div>
              <div>
                {/* Imagen 5 */}
                <img src="/home/carousel/gilera.png" />
                <img
                  className="bici-logo"
                  id="gilera-logo"
                  src="/home/carousel/logos/gilera.png"
                />
              </div>
              <div>
                {/* Imagen 6 */}
                <img src="/home/carousel/motomel.png" />
                <img
                  className="bici-logo"
                  id="motomel-logo"
                  src="/home/carousel/logos/motomel.png"
                />
              </div>
              <div>
                {/* Imagen 7 */}
                <img src="/home/carousel/zanella.png" />
                <img
                  className="bici-logo"
                  id="zanella-logo"
                  src="/home/carousel/logos/zanella.png"
                />
              </div>
              {/* Agregar mas imagenes dentro de un div aca, deben ser de 358x193px*/}
            </Carousel>
          </div>
          {/* Informacion Container */}
          <div id="informacion-container">
            {/* Titulo */}
            <h3>
              Aseguramos <span>todos los modelos</span>, desde motos hasta
              scooters y cuatris.
            </h3>
            {/* Texto */}
            <p>
              Deslizá para ver algunos de los modelos y marcas que aseguramos.
            </p>
            <p>
              ¿Tenés dudas respecto a tu vehículo? No dudes en contactarnos,
              <br></br>
              nosotros te ayudamos.
            </p>
          </div>
        </div>
      </Fade>
    </Container>
  );
}
