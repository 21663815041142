export const faqs = [
    {
        id: 1,
        pregunta: "¿Cómo puedo contratar el seguro de moto?",
        respuesta: <p itemprop="text">¡Es muy simple! Desde nuestro sitio web, podrás cotizar y se contactar con vos un asesor comercial que te brindara asesoramiento de manera simple y rápida para encontrar la cobertura y seguro de moto que mejor se adapte a tus necesidades."</p>
    },
    {
        id: 2,
        pregunta: "¿Cuando inicia la vigencia de mi póliza?",
        respuesta: <p itemprop="text">La póliza empieza a rendir desde las 12hs del día siguiente una vez contratado."</p>
    },
    {
        id: 3,
        pregunta: "¿Dónde llamar en caso de un siniestro?",
        respuesta: <p itemprop="text">Contactate de inmediato con nosotros por WhatsApp: 11 2806 1469 / 11 6209 6951, o mandanos un mail a: <a href="mailto:siniestros@seguroweb.com.ar">siniestros@seguroweb.com.ar</a>. Estaremos acompañándote en todo el proceso hasta el cobro de la indemnización.</p>
    }
];
  