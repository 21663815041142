import * as React from "react";
import { Collapse } from "react-collapse";
import Container from "./styled";
import { Link } from "react-scroll";
import "material-symbols";
import { BsCheckLg } from "react-icons/bs";
import { FaMoneyBillWave } from "react-icons/fa";
import { AiFillLike } from "react-icons/ai";
import { VscCircleFilled } from "react-icons/vsc";
import AnimacionCarga from "./animacion-carga";

export default function Plan({
  data,
  active,
  index,
  handleToggle,
  planSelected,
  nextStep,
  cotizacion,
  landing,
}) {

  // Continuar al siguiente paso
  const Continue = (e, plan) => {
    e.preventDefault();
    planSelected(plan, cotizacion);
    if(plan.precio){
      nextStep()
    } else {
      const ULTIMO_PASO = 3
      nextStep(ULTIMO_PASO)
    }
  };

  return (
    <Container
      mejorPrecio={data?.mejorPrecio}
      recomendado={data?.recomendado}
      seguro={data.nombre_compania}
    >
      {/* Dropdown */}
      <button
        className={data?.isActive ? "dropdown-abierto" : "dropdown-cerrado"}
        onClick={data?.clickFunction}
      >
        {data?.mejorPrecio && (
          <div className="mejor-precio shimmerPrecio">
            <p>
              <span>
                <FaMoneyBillWave></FaMoneyBillWave>
              </span>
              Mejor precio
            </p>
          </div>
        )}
        {data?.recomendado && (
          <div className="recomendado shimmerRecomendado">
            <p>
              <span>
                <AiFillLike></AiFillLike>
              </span>
              Recomendado
            </p>
          </div>
        )}
        {/* Logo and Plan Container */}
        <div className="logo-plan-container">
          <img alt="Logo Seguro" src={data.logo}></img>
          <p>{data.plan}</p>
        </div>
        {!data.plan_api || (data.plan_api && data.precio) || data?.cotizado ? (
          <>
            {/* Checks Container */}
            <div className="checks-container opacidad03">
              {/* Map para cada check */}
              {data.coberturas.map((cobertura) => {
                return (
                  <span
                    id={cobertura.cobertura_info?.principal}
                    className="check"
                  >
                    <p>{cobertura.cobertura_info?.nombre}</p>
                    {cobertura.activo ? (
                      <span class="material-symbols-outlined">done</span>
                    ) : (
                      <span id="check-no" class="material-symbols-outlined">
                        close
                      </span>
                    )}
                  </span>
                );
              })}
            </div>
            {/* Precios Container  */}
            <div className="precio-container opacidad03">
              {data.precio ? (
                <>
                  <div>
                    {data.cuotas == "" ? null : (
                      <p
                        id={data.precio == "" && "cuotas-bold"}
                        className={
                          data.precioAnterior == "" && "cuotas-naranja"
                        }
                      >
                        {data.cuotas}
                      </p>
                    )}
                    {data.precioAnterior == "" ? null : (
                      <p id="precio-anterior">{data.precioAnterior}</p>
                    )}
                  </div>
                  {data.precio == "" ? null : (
                    <div>
                      {data.oferta == "" ? null : <p>{data.oferta}</p>}
                      <p>${data.precio}</p>
                    </div>
                  )}
                  {data.contratando == "" ? null : (
                    <div>
                      <p>{data.contratando}</p>
                    </div>
                  )}
                </>
              ) : (
                <p id="precio-vacio"></p>
              )}
            </div>
            {/* Botones Container */}
            <div className="botones-container opacidad03">
              {!data.precio 
              ?
              <Link
                id="contrata-boton"
                className="btn-big"
                onClick={(e) => Continue(e, data)}
                to="header"
                spy={true}
              >
                Te contactamos
              </Link>
              :
              <Link
                id="contrata-boton"
                onClick={(e) => Continue(e, data)}
                to="header"
                spy={true}
              >
                ¡Contratá!
              </Link>
              }
              
              <button id="ver-mas-boton" onClick={() => handleToggle(index)}>
                {active === index ? "Cerrar cobertura" : "Ver cobertura"}
              </button>
            </div>
          </>
        ) : (
          <div id="buscando-container" className="opacidad03">
            <div id="animacion-loading">
              <AnimacionCarga></AnimacionCarga>
            </div>
          </div>
        )}
      </button>
      {/* Contenido */}
      <Collapse isOpened={active === index}>
        <div className="children-container">
          {/* Datos Container */}
          <div className="datos-container">
            {/* Plan Container */}
            <div className="plan-container">
              <p>{data.nombre_compania}</p>
              <p>-</p>
              <p>{data.plan}</p>
            </div>
            {/* Detalles Beneficios Container */}
            <div className="detalles-beneficios-container">
              <div
                className="detalles-container"
                id={data.beneficios == "" && "centrado"}
              >
                <h4>
                  <span>
                    <BsCheckLg />
                  </span>
                  Detalles de cobertura
                </h4>
                {data.detalles !== null &&
                  data.detalles.map((detalle) => {
                    return (
                      <div className="detalle">
                        <p>
                          <span>
                            <VscCircleFilled />
                          </span>
                          {detalle.detalle_info.detalle}
                        </p>
                      </div>
                    );
                  })}
              </div>
              {/* Beneficios Container */}
              <div
                className="beneficios-container"
                id={data.beneficios.length == "" && "none-beneficios"}
              >
                <h4>
                  <span>
                    <BsCheckLg />
                  </span>
                  Beneficios de la cobertura
                </h4>
                {data.beneficios !== null &&
                  data.beneficios.map((beneficio) => {
                    return (
                      <div className="beneficio">
                        <p>
                          <span>
                            <VscCircleFilled />
                          </span>
                          {beneficio.beneficio_info.beneficio}
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          {/* Vigencia Container */}
          <div className="vigencia-container">
            <p>
              Vigencia Anual - {data.precio && "Cuota mensual de:"}{" "}
              {data.precio ? `$${data.precio}` : "Consultar precio"}
            </p>
            <Link onClick={(e) => Continue(e, data)} to="header" spy={true}>
              {!data.precio ? "Te contactamos" : "¡Contratá!"}
            </Link>
          </div>
        </div>
      </Collapse>
    </Container>
  );
}
