import * as React from "react";
import Container from "./styled";
import { Fade } from "react-awesome-reveal";

export default function Pasos() {
  return (
    <Container>
      <div id="max-width-container">
        {/* Paso 1 */}
        <Fade delay={100} duration={800} className="paso-container">
          <div>
            <img src="/home/pasos/1.svg" className="flotar"></img>
            <p className="titulo-paso">Paso 1</p>
            <p className="texto-paso">
              Solicitá la cotización de tu moto desde el formulario en nuestra
              web
            </p>
          </div>
        </Fade>
        {/* Paso 2 */}
        <Fade delay={100} duration={800} className="paso-container">
          <div>
            <img src="/home/pasos/2.svg" className="flotar"></img>
            <p className="titulo-paso">Paso 2</p>
            <p className="texto-paso">
              Un asesor se pondrá en contacto con vos<br></br>(Días hábiles de 9
              a 18hs)
            </p>
          </div>
        </Fade>
        {/* Paso 3 */}
        <Fade delay={100} duration={800} className="paso-container">
          <div>
            <img src="/home/pasos/3.svg" className="flotar"></img>
            <p className="titulo-paso">Paso 3</p>
            <p className="texto-paso">
              ¡Ya tenes tu poliza!<br></br>Salí a manejar seguro y sin
              preocupaciones
            </p>
          </div>
        </Fade>
      </div>
    </Container>
  );
}
