import React, { useEffect } from "react";
import styled from "styled-components";
// Components
import Header from "../components/header";
import Titulo from "../components/titulo-institucionales";
import Footer from "../components/footer";
// Librerias
import Headroom from "react-headroom";
import Texto from "../components/institucional/texto";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  background-color: var(--blanco);
  overflow: hidden;
`;

export default function InstitucionalContainer({ pagina, titulo, tituloSpan }) {
  // Scroll ni bien carga la página
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  return (
    <Container>
      {/* Header */}
      <Headroom>
        <Header></Header>
      </Headroom>
      {/* Titulo */}
      <Titulo
        tituloSpan={tituloSpan}
        titulo={titulo}
        subtitulo="Actualizado a Dicimebre 2022"
        tag="Institucional"
      />
      {/* Texto */}
      <Texto pagina={pagina}></Texto>
      {/* Footer */}
      <Footer marcas="active" pagina="institucional"></Footer>
    </Container>
  );
}
