export const cotizadorForm = {
  nombre:
    localStorage.getItem("nombre-seguromotos") !== null
      ? localStorage.getItem("nombre-seguromotos")
      : "",
  apellido:
    localStorage.getItem("apellido-seguromotos") !== null
      ? localStorage.getItem("apellido-seguromotos")
      : "",
  email:
    localStorage.getItem("email-seguromotos") !== null
      ? localStorage.getItem("email-seguromotos")
      : "",
  codigoArea:
    localStorage.getItem("codigoArea-seguromotos") !== null
      ? localStorage.getItem("codigoArea-seguromotos")
      : "",
  telefono:
    localStorage.getItem("telefono-seguromotos") !== null
      ? localStorage.getItem("telefono-seguromotos")
      : "",
  provincia:
    localStorage.getItem("provincia-seguromotos") !== null
      ? localStorage.getItem("provincia-seguromotos")
      : "",
  localidad:
    localStorage.getItem("localidad-seguromotos") !== null
      ? localStorage.getItem("localidad-seguromotos")
      : "",
  vehiculo:
    localStorage.getItem("vehiculo-seguromotos") !== null
      ? localStorage.getItem("vehiculo-seguromotos")
      : "",
  marca:
    localStorage.getItem("marca-seguromotos") !== null
      ? localStorage.getItem("marca-seguromotos")
      : "",
  modelo:
    localStorage.getItem("modelo-seguromotos") !== null
      ? localStorage.getItem("modelo-seguromotos")
      : "",
  antiguedad:
    localStorage.getItem("antiguedad-seguromotos") !== null
      ? localStorage.getItem("antiguedad-seguromotos")
      : "",
};

export const referidosForm = {
  email:
    localStorage.getItem("email-seguromotos") !== null
      ? localStorage.getItem("email-seguromotos")
      : "",
  emailReferido: "",
  nombreReferido: "",
  captcha: undefined
}

export const arrepentimientoForm = {
  nombre:
    localStorage.getItem("nombre-seguromotos") !== null
      ? localStorage.getItem("nombre-seguromotos")
      : "",
  apellido:
    localStorage.getItem("apellido-seguromotos") !== null
      ? localStorage.getItem("apellido-seguromotos")
      : "",
  email:
    localStorage.getItem("email-seguromotos") !== null
      ? localStorage.getItem("email-seguromotos")
      : "",
  dni: '',
  telefono:
    localStorage.getItem("telefono-seguromotos") !== null
      ? localStorage.getItem("telefono-seguromotos")
      : "",
  mensaje: '',
  captcha: undefined
}

export const bajaServicioForm = {
  nombre:
    localStorage.getItem("nombre-seguromotos") !== null
      ? localStorage.getItem("nombre-seguromotos")
      : "",
  apellido:
    localStorage.getItem("apellido-seguromotos") !== null
      ? localStorage.getItem("apellido-seguromotos")
      : "",
  email:
    localStorage.getItem("email-seguromotos") !== null
      ? localStorage.getItem("email-seguromotos")
      : "",
  dni: '',
  telefono:
    localStorage.getItem("telefono-seguromotos") !== null
      ? localStorage.getItem("telefono-seguromotos")
      : "",
  mensaje: '',
  captcha: undefined
}

export const contactoForm = {
  nombre:
  localStorage.getItem("nombre-seguromotos") !== null
    ? localStorage.getItem("nombre-seguromotos")
    : "",
  email:
    localStorage.getItem("email-seguromotos") !== null
      ? localStorage.getItem("email-seguromotos")
      : "",
  telefono:
    localStorage.getItem("telefono-seguromotos") !== null
      ? localStorage.getItem("telefono-seguromotos")
      : "",
  asunto: '',
  mensaje: '',
  captcha: undefined
}

export const formaParteForm = {
  nombre:
    localStorage.getItem("nombre-seguromotos") !== null
    ? localStorage.getItem("nombre-seguromotos")
    : "",
  apellido:
    localStorage.getItem("apellido-seguromotos") !== null
      ? localStorage.getItem("apellido-seguromotos")
      : "",
  email:
    localStorage.getItem("email-seguromotos") !== null
      ? localStorage.getItem("email-seguromotos")
      : "",
  puesto: '',
  mensaje: '',
  cv: '',
  captcha: undefined
}

// *** Contratá *** //
export const contrataForm = {
  nombre: localStorage.getItem("nombre-seguromotos") ? localStorage.getItem("nombre-seguromotos") : "",
  apellido: localStorage.getItem("apellido-seguromotos") ? localStorage.getItem("apellido-seguromotos") : "",
  email: localStorage.getItem("email-seguromotos") ? localStorage.getItem("email-seguromotos") : "",
  codigoArea: localStorage.getItem("codigo-area-seguromotos") ? localStorage.getItem("codigo-area-seguromotos") : "",
  telefono: localStorage.getItem("telefono-seguromotos") ? localStorage.getItem("telefono-seguromotos") : "",
  // Datos forma de pago
  formaDePago: "Tarjeta de credito",
  numeroTarjeta: "",
  bancoEmisor: "",
  vencimiento: "",
  titular: "",
  cuitDNI: "",
  codigoPostal: "",
  cvc: "",
  cbu: "",
  dniTitular: "",
  tipo_tarjeta: "",
}