import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  max-width: 1920px;
  background-color: var(--blanco);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  // Botón Atras Container
  .boton-atras-container {
    width: 100%;
    max-width: 1270px;
    margin: 0px 0px -32px 0px;
    display: flex;
    justify-content: flex-start;
    z-index: 98;
  }

  // Botón Atras
  .boton-atras-container button {
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-size: 14px;
    background-color: var(--blanco);
    border: 0px;
    cursor: pointer;
    transition: 0.2s all !important;
  }

  // Botón Atras Icono
  .boton-atras-container button span {
    margin: 4px 10px 0px 0px;
    font-size: 20px;
    transform: translate(0, 0);
    transition: 0.2s all !important;
  }

  // Botón Atras Hover
  .boton-atras-container button:hover {
    color: var(--lila90);
  }

  // Botón Atras Hover Iconok
  .boton-atras-container button:hover > span {
    color: var(--lila90);
    transform: translate(-10px, 0);
  }

  // Dropdowns Container
  .dropdowns-container {
    width: 100%;
    margin: 0px 0px 120px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Titulos
  .titulo-section {
    width: 1270px;
    text-align: left;
    margin: 80px 0px -24px 0px;
    color: var(--gris);
    font-weight: 400;
    font-size: 14px;
  }

  #segundo-titulo {
    margin: 64px 0px -24px 0px;
  }

  // Datos Fade Container
  .datos-fade-container {
    width: 100%;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
  }

  // Datos Container
  #datos-container {
    width: 100%;
    max-width: 1222px;
    height: 115px;
    margin: 56px 0px 0px 0px;
    padding: 0px 24px 0px 24px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    pointer-events: none;
    background-color: #fcfcfc;
    border-radius: 12px !important;
    box-shadow: 0px 5px 30px var(--sombraNaranja);
    border: 1px solid var(--grisClaro);
    transition: 0.2s all;
  }

  // Datos Vehiculo
  .datos-vehiculo {
    width: 115px;
    height: 100%;
    padding: 0px 24px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    border-right: 1px solid var(--grisClaro);
  }

  .datos-vehiculo img {
    width: 100%;
    max-height: 30px;
    height: auto;
    object-fit: contain;
  }

  .datos-vehiculo p {
    margin: 16px 0px 0px 0px;
    font-weight: 600;
    font-size: 13px;
    text-align: center;
    color: var(--naranja100);
    line-height: 16px;
  }

  .informacion-vehiculo {
    width: 200px;
    height: 100%;
    padding: 0px 24px 0px 32px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    border-right: 1px solid var(--grisClaro);
    position: relative;
    overflow: hidden;
  }

  .informacion-vehiculo p {
    width: 100%;
    margin: 0px 0px 0px 0px;
    font-weight: 600;
    font-size: 16px;
    text-align: left;
    color: var(--naranja100);
    line-height: 10px;
  }

  .informacion-vehiculo p:nth-child(2) {
    width: 100%;
    margin: 12px 0px 0px 0px;
    font-size: 14px;
    font-weight: 600;
    color: var(--lila90);
    text-align: left;
    line-height: 10px;
  }

  .informacion-vehiculo p:nth-child(3) {
    width: 100%;
    margin: 16px 0px 0px 0px;
    font-weight: 400;
    font-size: 12px;
    text-align: left;
    color: var(--gris);
    line-height: 10px;
  }

  .informacion-vehiculo img {
    position: absolute;
    width: 80px;
    top: 12;
    right: -38px;
    opacity: 0.2;
  }

  // Datos Cotización
  #datos-cotizacion {
    height: 100%;
    padding: 0px 32px 0px 32px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
  }

  // Dato
  .dato {
    width: fit-content;
    margin: 0px 32px 0px 0px;
  }

  // Dato Titulo
  .dato p {
    margin: 0px 0px 0px 0px;
    font-size: 12px;
    font-weight: 400;
    color: var(--gris);
  }

  // Dato Texto
  .dato p:nth-child(2) {
    font-size: 14px;
    font-weight: 600;
    color: var(--lila90);
  }

  // Dropdown Container
  .dropdown-container,
  .dropdown-mejor-precio,
  .dropdown-recomendado {
    width: 100%;
    max-width: 1270px;
    margin: 56px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    text-align: center;
    border: 1px solid var(--grisClaro);
    border-radius: 12px;
    background-color: #fcfcfc;
    box-shadow: 0px 5px 30px var(--sombraNaranja);
    transition: 0.2s all;
  }

  .mejor-precio,
  .dropdown-mejor-precio {
    box-shadow: 0px 5px 30px rgba(152, 152, 154, 0.7) !important;
  }

  .recomendado,
  .dropdown-recomendado {
    box-shadow: 0px 5px 30px rgba(218, 165, 32, 0.4) !important;
  }

  // Datos Container
  .datos-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  // Plan Container
  .plan-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  // Plan Container Text
  .plan-container p {
    margin: 32px 4px 0px 4px;
    text-align: center;
    font-weight: 700;
    color: var(--lila90);
  }

  // Detalles Beneficios Container
  .detalles-beneficios-container {
    width: 100%;
    padding: 32px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  // Detalles Beneficios Container Titulo
  .detalles-beneficios-container h4 {
    width: 100%;
    margin: 0px 0px 32px 0px;
    text-align: center;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  // Detalles Beneficios Container Titulo Span
  .detalles-beneficios-container h4 span {
    margin: 4px 10px 0px 0px;
    color: var(--lila90);
    font-size: 14px;
  }

  // Detalles Container y Beneficios Container
  .detalles-container,
  .beneficios-container {
    width: 40%;
    margin: 0% 3% 0% 3%;
    max-width: 430px;
  }

  // None Benfeicios
  #none-beneficios {
    display: none !important;
  }

  // Detalles Container Titulo y Beneficios Container Titulo
  .detalles-container h4,
  .beneficios-container h4 {
    margin: 0px 0px 24px 0px;
    text-align: left;
    font-size: 13px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
  }

  // Detalles Container Titulo Icono y Beneficios Container Titulo Icono
  .detalles-container h4 span,
  .beneficios-container h4 span {
    margin: 5px 8px 0px 0px;
    color: var(--naranja100);
    font-size: 21px;
  }

  // Detalle y Beneficio
  .detalle,
  .beneficio {
    width: 100%;
    margin: 0px 0px 3px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Detalle Texto y Beneficio Text
  .detalle p,
  .beneficio p {
    margin: 0px 0px 0px 0px;
    text-align: left !important;
    line-height: 28px;
    color: var(--gris);
    font-weight: 400;
    font-size: 13px;
    white-space: pre-line;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Detalle Icono y Beneficio Icono
  .detalle p span,
  .beneficio p span {
    width: 13px;
    height: 100%;
    display: block;
    margin: 0px 8px 0px 0px;
    color: var(--naranja100);
    font-size: 12px;
  }

  // Vigencia Container
  .vigencia-container {
    width: 100%;
    margin: 40px 0px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    border-top: 1px solid var(--grisClaro);
  }

  // Vigencia Container Text
  .vigencia-container p {
    width: 100%;
    margin: 32px 0px 0px 0px;
    text-align: center;
    font-size: 14px;
    line-height: 27px;
    font-weight: 600;
    color: var(--lila90);
  }

  // Vigencia Container Botones
  .vigencia-container a {
    margin: 24px 0px 32px 0px;
    padding: 8px 24px 8px 24px;
    background-color: var(--naranja100);
    color: var(--blanco);
    text-align: center;
    font-weight: 600;
    font-size: 13px;
    border: 0;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.2s all;
  }

  // Vigencia Container Botones Hover
  .vigencia-container a:hover {
    background-color: var(--naranja40);
  }

  // Cobertura Laboral Container
  .cobertura-laboral-container {
    width: 1270px;
    padding: 0px 0px 0px 4px;
    margin: 80px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
  }

  #icono-peligro {
    font-size: 22px;
    color: var(--lila90);
  }

  #texto-laboral {
    font-family: "DM Sans", sans-serif !important;
    text-align: left;
    color: var(--gris);
    font-weight: 400;
    font-size: 14px;
  }

  // Cobertura Laboral
  #cobertura-laboral {
    width: 100%;
    font-family: "DM Sans", sans-serif !important;
    margin: 0px 0px 0px 0px;
    text-align: left;
    color: var(--gris);
    font-weight: 400;
    font-size: 14px;
  }

  // Pregunta Laboral
  #pregunta-laboral {
    font-family: "DM Sans", sans-serif;
    margin: 2px 0px 0px 0px;
    color: var(--gris);
    font-weight: 400;
    font-size: 14px;
  }

  // Cobertura Laboral Botón
  #cobertura-laboral a {
    font-family: "DM Sans", sans-serif;
    margin: 2px 0px 0px 5px;
    text-decoration: none;
    color: var(--naranja100);
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    transition: 0.2s all;
  }

  // Cobertura Laboral Botón Hover
  #cobertura-laboral a:hover {
    color: var(--lila90);
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 1000px;
    }

    // Titulos
    .titulo-section {
      width: 1000px;
    }

    // Datos Container
    #datos-container {
      max-width: 952px;
    }

    // Dropdown Container
    .dropdown-container,
    .dropdown-mejor-precio,
    .dropdown-recomendado {
      max-width: 1000px;
    }

    // Detalles Container y Beneficios Container
    .detalles-container,
    .beneficios-container {
      width: 40%;
      max-width: 340px;
    }

    // Cobertura Laboral Container
    .cobertura-laboral-container {
      width: 1000px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 680px;
      margin: 32px 0px 0px 0px;
    }

    // Titulos
    .titulo-section {
      width: 680px;
      margin: 60px 0px -24px 0px;
    }

    // Datos Container
    #datos-container {
      max-width: 632px;
    }

    // Datos Vehiculo
    .datos-vehiculo {
      width: 95px;
    }

    #informacion-vehiculo {
      width: 180px;
    }

    #dato-provincia {
      display: none;
    }

    // Dropdowns Container
    .dropdowns-container {
      max-width: 680px;
      margin: 0px 0px 120px 0px;
    }

    // Dropdown Container
    .dropdown-container,
    .dropdown-mejor-precio,
    .dropdown-recomendado {
      margin: 56px 0px 0px 0px;
      max-width: 680px;
    }

    .plan-container p {
      margin: 16px 4px 0px 4px;
    }

    // Detalles Beneficios Container
    .detalles-beneficios-container {
      margin: 16px 0px 0px 0px;
    }

    // Datos Container
    .datos-container {
      width: 80%;
      margin: 24px 0px 0px 0px;
    }

    // Detalles Container y Beneficios Container
    .detalles-container,
    .beneficios-container {
      width: 100%;
      margin: 16px 0px 16px 0px;
      max-width: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
    }

    // Detalles Container Titulo y Beneficios Container Titulo
    .detalles-container h4,
    .beneficios-container h4 {
      margin: 0px 0px 16px 0px;
    }

    // Beneficios Container
    .beneficios-container {
      margin: 0px 0px 0px 0px;
    }

    // Detalles Container Centrado Título
    #centrado h4 {
      text-align: left;
      justify-content: flex-start;
    }

    // Detalles Container Centrado Detalle
    #centrado .detalle {
      justify-content: left;
    }

    #centrado .detalle p {
      justify-content: left !important;
    }

    #centrado .detalle p,
    #centrado .beneficio p {
      text-align: left !important;
    }

    // Vigencia Container
    .vigencia-container {
      width: 100%;
      margin: 32px 0px 0px 0px;
    }

    // Cobertura Laboral
    #cobertura-laboral {
      width: 87%;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 480px;
    }

    // Titulos
    .titulo-section {
      width: 480px;
      margin: 60px 0px -12px 0px;
    }

    #segundo-titulo {
      margin: 40px 0px -24px 0px;
    }

    // Datos Container
    #datos-container {
      max-width: 432px;
      margin: 24px 0px 0px 0px;
    }

    // Información Vehículo
    .informacion-vehiculo {
      display: none !important;
    }

    // Dropdowns Container
    .dropdowns-container {
      max-width: 480px;
    }

    // Dropdown Container
    .dropdown-container,
    .dropdown-mejor-precio,
    .dropdown-recomendado {
      max-width: 480px;
      margin: 40px 0px 0px 0px;
    }

    // Cobertura Laboral
    #cobertura-laboral {
      width: 100%;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 350px;
    }

    // Titulos
    .titulo-section {
      width: 350px;
    }

    // Datos Container
    #datos-container {
      display: none;
      max-width: 326px;
      padding: 0px 16px 0px 16px;
    }

    // Datos Vehiculo
    .datos-vehiculo {
      display: none;
    }

    // Dropdowns Container
    .dropdowns-container {
      max-width: 350px;
    }

    // Dropdown Container
    .dropdown-container,
    .dropdown-mejor-precio,
    .dropdown-recomendado {
      max-width: 350px;
    }

    // Dropdowns Container
    .dropdowns-container {
      margin: 0px 0px 40px 0px;
    }
  }

  // 414px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile414}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 90%;
    }

    // Titulos
    .titulo-section {
      width: 90%;
    }

    // Datos Container
    #datos-container {
      max-width: calc(90% - 16px);
      padding: 0px 8px 0px 8px;
    }

    // Dropdowns Container
    .dropdowns-container {
      display: flex;
    }

    // Cobertura Laboral
    .cobertura-laboral-container {
      max-width: 90%;
    }
  }

  // 385px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile385}) {
    // Dropdown Container
    .dropdown-container,
    .dropdown-mejor-precio,
    .dropdown-recomendado {
      max-width: 90%;
      padding: 24px 0px 24px 0px;
    }
  }
`;

export default Container;
