import { useEffect } from "react"

export const useTracking = () => {

    useEffect(() => {
        const scriptPixel = document.createElement("script");
        const scriptGoogleAds = document.createElement("script");
    
        scriptPixel.text = `fbq('track', 'Lead', {})`;
        scriptGoogleAds.text = `gtag('event', 'conversion', {'send_to': 'AW-959485109/uy9TCIC7nFcQtanCyQM'})`;
    
        document.body.appendChild(scriptPixel);
        document.head.appendChild(scriptGoogleAds);
    
        return () => {
          document.body.removeChild(scriptPixel);
          document.head.removeChild(scriptGoogleAds);
        };
    }, []);
    
}