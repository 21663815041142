import valid from "card-validator";

// *** Cotizador *** //
export const cotizadorReglas = (state) => {
    return {
        nombre: [
            {
                regla: state.nombre === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.nombre.length < 2,
                mensaje: 'El campo nombre debe contener al menos 2 caracteres.'
            },
            {
                regla: !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(state.nombre),
                mensaje: 'El nombre ingresado no es válido.'
            },
        ],
        apellido: [
            {
                regla: state.apellido === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.apellido.length < 2,
                mensaje: 'El campo apellido debe contener al menos 2 caracteres.'
            },
            {
                regla: !/^[a-zA-Z!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(state.apellido),
                mensaje: 'El apellido ingresado no es válido.'
            },
        ],
        email: [
            {
                regla: state.email === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email),
                mensaje: 'El email ingresado no es válido.'
            },
        ],
        provincia: [
            {
                regla: state.provincia === "",
                mensaje: 'Este campo es requerido.'
            }
        ],
        localidad: [
            {
                regla: state.localidad === "",
                mensaje: 'Este campo es requerido.'
            }
        ],
        marca: [
            {
                regla: state.marca === "",
                mensaje: 'Este campo es requerido.'
            }
        ],
        vehiculo: [
            {
                regla: state.vehiculo === "",
                mensaje: 'Este campo es requerido.'
            }
        ],
        modelo: [
            {
                regla: state.modelo === "",
                mensaje: 'Este campo es requerido.'
            }
        ],
        antiguedad: [
            {
                regla: state.antiguedad === "",
                mensaje: 'Este campo es requerido.'
            }
        ],
        telefono: [
            {
                regla: state.telefono === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.telefono.length < 8,
                mensaje: 'El campo telefono es menor a 8 digitos.'
            },
        ],
        codigoArea: [
            {
                regla: state.codigoArea === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.codigoArea.length < 2 ||
                state.codigoArea.length > 5,
                mensaje: 'El código de área debe tener más de 2 digitos y menos de 5.'
            },
        ],
    }
};

// *** Referidos *** //
export const referidosReglas = (state) => {
    return {
        nombreReferido: [
            {
                regla: state.nombreReferido === "",
                mensaje: 'El campo "Nombre" en "Datos del referido" es requerido.'
            },
            {
                regla: state.nombreReferido.length < 2,
                mensaje: 'El campo nombre del referido debe contener al menos 2 caracteres.'
            },
            {
                regla: !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(state.nombreReferido),
                mensaje: 'El nombre del referido ingresado no es válido.'
            },
        ],
        email: [
            {
                regla: state.email === '',
                mensaje: 'El campo "Email" en "Datos personales" es requerido.'
            },
            {
                regla: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email),
                mensaje: 'El email ingresado no es válido.'
            },
        ],
        emailReferido: [
            {
                regla: state.emailReferido === '',
                mensaje: 'El campo "Email" en "Datos del referido" es requerido.'
            },
            {
                regla: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.emailReferido),
                mensaje: 'El email referido ingresado no es válido.'
            },
        ],
        captcha: [
            {
                regla: state.captcha === undefined,
                mensaje: 'Confirme la verificación reCaptcha.'
            },
        ],
    }
};

// *** Arrepentimiento *** //
export const arrepentimientoReglas = (state) => {
    return {
        nombre: [
            {
                regla: state.nombre === "",
                mensaje: 'El campo nombre es requerido.'
            },
            {
                regla: state.nombre.length < 2,
                mensaje: 'El campo nombre debe contener al menos 2 caracteres.'
            },
            {
                regla: !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(state.nombre),
                mensaje: 'El nombre ingresado no es válido.'
            },
        ],
        apellido: [
            {
                regla: state.apellido === "",
                mensaje: 'El campo apellido es requerido.'
            },
            {
                regla: state.apellido.length < 2,
                mensaje: 'El campo apellido debe contener al menos 2 caracteres.'
            },
            {
                regla: !/^[a-zA-Z!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(state.apellido),
                mensaje: 'El apellido ingresado no es válido.'
            },
        ],
        email: [
            {
                regla: state.email === "",
                mensaje: 'El campo apellido es requerido.'
            },
            {
                regla: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email),
                mensaje: 'El email ingresado no es válido.'
            },
        ],
        dni: [
            {
                regla: state.dni === "",
                mensaje: 'El campo DNI es requerido.'
            }
        ],
        telefono: [
            {
                regla: state.telefono === "",
                mensaje: 'El campo teléfono es requerido.'
            },
            {
                regla: state.telefono.length < 8,
                mensaje: 'El campo telefono es menor a 8 digitos.'
            },
        ],
        mensaje: [
            {
                regla: state.mensaje === "",
                mensaje: 'Tu comentario es requerido.'
            },
        ],
        captcha: [
            {
                regla: state.captcha === undefined,
                mensaje: 'Confirme la verificación reCaptcha.'
            },
        ],
    }
};

// *** Baja Servicio *** //
export const bajaServicioReglas = (state) => {
    return {
        nombre: [
            {
                regla: state.nombre === "",
                mensaje: 'El campo nombre es requerido.'
            },
            {
                regla: state.nombre.length < 2,
                mensaje: 'El campo nombre debe contener al menos 2 caracteres.'
            },
            {
                regla: !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(state.nombre),
                mensaje: 'El nombre ingresado no es válido.'
            },
        ],
        apellido: [
            {
                regla: state.apellido === "",
                mensaje: 'El campo apellido es requerido.'
            },
            {
                regla: state.apellido.length < 2,
                mensaje: 'El campo apellido debe contener al menos 2 caracteres.'
            },
            {
                regla: !/^[a-zA-Z!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(state.apellido),
                mensaje: 'El apellido ingresado no es válido.'
            },
        ],
        email: [
            {
                regla: state.email === "",
                mensaje: 'El campo apellido es requerido.'
            },
            {
                regla: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email),
                mensaje: 'El email ingresado no es válido.'
            },
        ],
        dni: [
            {
                regla: state.dni === "",
                mensaje: 'El campo DNI es requerido.'
            }
        ],
        telefono: [
            {
                regla: state.telefono === "",
                mensaje: 'El campo teléfono es requerido.'
            },
            {
                regla: state.telefono.length < 8,
                mensaje: 'El campo telefono es menor a 8 digitos.'
            },
        ],
        mensaje: [
            {
                regla: state.mensaje === "",
                mensaje: 'Tu comentario es requerido.'
            },
        ],
        captcha: [
            {
                regla: state.captcha === undefined,
                mensaje: 'Confirme la verificación reCaptcha.'
            },
        ],
    }
};

// *** Contacto *** //
export const contactoReglas = (state) => {
    return {
        nombre: [
            {
                regla: state.nombre === "",
                mensaje: 'El campo nombre es requerido.'
            },
            {
                regla: state.nombre.length < 2,
                mensaje: 'El campo nombre debe contener al menos 2 caracteres.'
            },
            {
                regla: !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(state.nombre),
                mensaje: 'El nombre ingresado no es válido.'
            },
        ],
        email: [
            {
                regla: state.email === "",
                mensaje: 'El campo apellido es requerido.'
            },
            {
                regla: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email),
                mensaje: 'El email ingresado no es válido.'
            },
        ],
        telefono: [
            {
                regla: state.telefono === "",
                mensaje: 'El campo teléfono es requerido.'
            },
            {
                regla: state.telefono.length < 8,
                mensaje: 'El campo telefono es menor a 8 digitos.'
            },
        ],
        asunto: [
            {
                regla: state.asunto === "",
                mensaje: 'El campo asunto es requerido.'
            }
        ],
        mensaje: [
            {
                regla: state.mensaje === "",
                mensaje: 'Tu comentario es requerido.'
            },
        ],
        captcha: [
            {
                regla: state.captcha === undefined,
                mensaje: 'Confirme la verificación reCaptcha.'
            },
        ],
    }
};

// *** Forma Parte *** //
export const formaParteReglas = (state) => {
    return {
        nombre: [
            {
                regla: state.nombre === "",
                mensaje: 'El campo nombre es requerido.'
            },
            {
                regla: state.nombre.length < 2,
                mensaje: 'El campo nombre debe contener al menos 2 caracteres.'
            },
            {
                regla: !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(state.nombre),
                mensaje: 'El nombre ingresado no es válido.'
            },
        ],
        apellido: [
            {
                regla: state.apellido === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.apellido.length < 2,
                mensaje: 'El campo apellido debe contener al menos 2 caracteres.'
            },
            {
                regla: !/^[a-zA-Z!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(state.apellido),
                mensaje: 'El apellido ingresado no es válido.'
            },
        ],
        email: [
            {
                regla: state.email === "",
                mensaje: 'El campo apellido es requerido.'
            },
            {
                regla: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email),
                mensaje: 'El email ingresado no es válido.'
            },
        ],
        puesto: [
            {
                regla: state.puesto === "",
                mensaje: 'El campo puesto es requerido.'
            }
        ],
        mensaje: [
            {
                regla: state.mensaje === "",
                mensaje: 'Tu comentario es requerido.'
            },
        ],
        cv: [
            {
                regla: state.cv === "",
                mensaje: 'Tu CV es requerido.'
            }
        ],
        captcha: [
            {
                regla: state.captcha === undefined,
                mensaje: 'Confirme la verificación reCaptcha.'
            },
        ],
    }
};

// *** Forma Parte *** //
export const contrataReglas = (state) => {

    const notValidCBUs = ["0143", "0147", "0158", "0165", "0384", "000000"];

    return {
        nombre: [
            {
                regla: state.nombre === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.nombre.length < 2,
                mensaje: 'El campo nombre debe contener al menos 2 caracteres.'
            },
            {
                regla: !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(state.nombre),
                mensaje: 'El nombre ingresado no es válido.'
            },
        ],
        apellido: [
            {
                regla: state.apellido === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.apellido.length < 2,
                mensaje: 'El campo apellido debe contener al menos 2 caracteres.'
            },
            {
                regla: !/^[a-zA-Z!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(state.apellido),
                mensaje: 'El apellido ingresado no es válido.'
            },
        ],
        email: [
            {
                regla: state.email === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(state.email),
                mensaje: 'El email ingresado no es válido.'
            },
        ],
        telefono: [
            {
                regla: state.telefono === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.telefono.length < 8,
                mensaje: 'El campo telefono es menor a 8 digitos.'
            },
        ],
        codigoArea: [
            {
                regla: state.codigoArea === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.codigoArea.length < 2 ||
                state.codigoArea.length > 5,
                mensaje: 'El código de área debe tener más de 2 digitos y menos de 5.'
            },
        ],
        codigoPostal: [
            {
                regla: state.codigoPostal === "",
                mensaje: 'Este campo es requerido.'
            },
        ],
        cuitDNI: [
            {
                regla: state.cuitDNI === "",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.cuitDNI.length < 8 ||
                state.cuitDNI.length > 11,
                mensaje: 'El CUIT/DNI es inválido.'
            },
        ],
        formaDePago: [
            {
                regla: state.formaDePago === "",
                mensaje: 'Este campo es requerido.'
            }
        ],
        titular: [
            {
                regla: state.titular === "" && state.formaDePago === "Tarjeta de credito",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: !valid.cardholderName(state.titular).isValid && state.formaDePago === "Tarjeta de credito",
                mensaje: 'El nombre ingresado no es válido.'
            },
            {
                regla: state.titular.length < 2 && state.formaDePago === "Tarjeta de credito",
                mensaje: 'El campo Titular de la tarjeta debe contener al menos 2 caracteres'
            },
            {
                regla: !/^[a-zA-Z\u00C0-\u017F!@#$%^&':"\\|,.<>\/?\s\u00f1\u00d1]*$/.test(state.titular) && state.formaDePago === "Tarjeta de credito",
                mensaje: 'El nombre ingresado no es válido.'
            },
        ],
        numeroTarjeta: [
            {
                regla: state.numeroTarjeta === "" && state.formaDePago === "Tarjeta de credito",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: (state.numeroTarjeta.startsWith("4517") || state.numeroTarjeta.startsWith("4815") || state.numeroTarjeta.startsWith("5285")) && state.formaDePago === "Tarjeta de credito",
                mensaje: 'El número de tarjeta ingresado corresponde a una tarjeta de debito.'
            },
            {
                regla: !valid.number(state.numeroTarjeta).isValid && state.formaDePago === "Tarjeta de credito",
                mensaje: 'El número de tarjeta ingresado no es válido.'
            },
            {
                regla: !valid.number(state.numeroTarjeta).isValid && state.formaDePago === "Tarjeta de credito",
                mensaje: 'El número de tarjeta ingresado no es válido.'
            },
        ],
        vencimiento: [
            {
                regla: state.vencimiento === "" && state.formaDePago === "Tarjeta de credito",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: !valid.expirationDate(state.vencimiento).isValid && state.formaDePago === "Tarjeta de credito",
                mensaje: 'La fecha de vencimiento ingresada no es válida.'
            },
        ],
        dniTitular: [
            {
                regla: state.dniTitular === "" && state.formaDePago === "Tarjeta de credito",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.dniTitular.length !== 8 && state.formaDePago === "Tarjeta de credito",
                mensaje: 'El campo DNI solo permite 8 digitos.'
            },
        ],
        bancoEmisor: [
            {
                regla: state.bancoEmisor === "" && state.formaDePago === "Tarjeta de credito",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: !/^[A-Za-z'\s\u00C0-\u017F]+$/.test(state.bancoEmisor) && state.formaDePago === "Tarjeta de credito",
                mensaje: 'El Banco emisor ingresado no es válido.'
            },
        ],
        cbu: [
            {
                regla: state.cbu === "" && state.formaDePago === "Transferencia bancaria",
                mensaje: 'Este campo es requerido.'
            },
            {
                regla: state.cbu.length !== 22 && state.formaDePago === "Transferencia bancaria",
                mensaje: `El CBU ingresado debe contener 22 digitos. El valor ingresado tiene ${state.cbu.length} digitos.`
            },
            {
                regla: notValidCBUs.some(cbuNumber => state.cbu.startsWith(cbuNumber)) && state.formaDePago === "Transferencia bancaria",
                mensaje: `CBU no bancarizado.`
            },
        ],
    }
};