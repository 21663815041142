import * as React from "react";
import { Fade } from "react-awesome-reveal";
import Marquee from "react-fast-marquee";
import Container from "./styled";

export default function Logos() {
  return (
    <Container>
      {/* Logos Container */}
      <div className="logos-container opacidad08">
        {/* Logo */}
        <img
          alt="Rio Uruguay Seguros Logo"
          src="/logos/rioUruguay.svg"
          id="rus-logo"
        ></img>
        {/* Logo */}
        <img
          alt="ATM Seguros Logo"
          src="/logos/atmseguros.svg"
          id="atm-logo"
        ></img>
        {/* Logo */}
        <img
          alt="Federación Patronal Seguros Logo"
          src="/logos/federacionpatronal.svg"
          id="patronal-logo"
        ></img>
        {/* Logo */}
        <img
          alt="San Cristobal Compañia de Seguros Logo"
          src="/logos/sanCristobal.svg"
          id="colon-logo"
        ></img>
        {/* Logo */}
        <img
          alt="Allianz Compañia de Seguros Logo"
          src="/logos/allianz.svg"
          id="colon-logo"
        ></img>
        {/* Logo */}
        <img
          alt="Integrity Compañia de Seguros Logo"
          src="/logos/integritySeguros.svg"
          id="colon-logo"
        ></img>
      </div>
      {/* Marquee Container */}
      <Fade className="fade-container-marquee" delay={50} duration={700}>
        <Marquee gradient={true} speed="45">
          {/* Logo */}
          <img
            alt="Rio Uruguay Seguros Logo"
            src="/logos/rioUruguay.svg"
            id="rus-logo"
          ></img>
          {/* Logo */}
          <img
            alt="ATM Seguros Logo"
            src="/logos/atmseguros.svg"
            id="atm-logo"
          ></img>
          {/* Logo */}
          <img
            alt="Federación Patronal Seguros Logo"
            src="/logos/federacionpatronal.svg"
            id="patronal-logo"
          ></img>
          {/* Logo */}
          <img
            alt="San Cristobal Compañia de Seguros Logo"
            src="/logos/sanCristobal.svg"
            id="colon-logo"
          ></img>
          {/* Logo */}
          <img
            alt="Allianz Compañia de Seguros Logo"
            src="/logos/allianz.svg"
            id="colon-logo"
          ></img>
          {/* Logo */}
          <img
            alt="Integrity Compañia de Seguros Logo"
            src="/logos/integritySeguros.svg"
            id="colon-logo"
          ></img>
                    {/* Logo */}
                    <img
            alt="Rio Uruguay Seguros Logo"
            src="/logos/rioUruguay.svg"
            id="rus-logo"
          ></img>
          {/* Logo */}
          <img
            alt="ATM Seguros Logo"
            src="/logos/atmseguros.svg"
            id="atm-logo"
          ></img>
          {/* Logo */}
          <img
            alt="Federación Patronal Seguros Logo"
            src="/logos/federacionpatronal.svg"
            id="patronal-logo"
          ></img>
          {/* Logo */}
          <img
            alt="San Cristobal Compañia de Seguros Logo"
            src="/logos/sanCristobal.svg"
            id="colon-logo"
          ></img>
          {/* Logo */}
          <img
            alt="Allianz Compañia de Seguros Logo"
            src="/logos/allianz.svg"
            id="colon-logo"
          ></img>
          {/* Logo */}
          <img
            alt="Integrity Compañia de Seguros Logo"
            src="/logos/integritySeguros.svg"
            id="colon-logo"
          ></img>
        </Marquee>
      </Fade>
    </Container>
  );
}
