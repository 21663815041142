import axios from 'axios'
import url from '../utils/url';

export const getCotizacionRUS = async (data) => {
    try {   
        console.log(data)
        const rus = await axios.post(`${url}/api/cotizaciones/rus`, data);
        return rus.data
    } catch (error) {
        console.log(error)
    }
}