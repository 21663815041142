export const PARAMS = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "influencer",
    "fbclid",
    "network",
    "campaign",
    "group",
    "creative",
    "keyword",
    "device",
    "matchtype",
    "gclid",
]

export const PARAMSObj = {
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    influencer: null,
    fbclid: null,
    network: null,
    campaign: null,
    group: null,
    creative: null,
    keyword: null,
    device: null,
    matchtype: null,
    gclid: null,
}