import Marcador from "../marcador/index";
import { BsCheckLg } from "react-icons/bs";
import Container from "./styled";
import Sound from "react-sound";
import Animacion from "./animacion";
import Timbre from "./timbre.mp3";
import NumberFormat from "react-number-format";
// Custom Hooks
import { useScrollTop } from "../../../hooks/useScrollTop";
import { useConsulta } from "../../../hooks/useConsulta";

export default function Contrataste({ planSelected, cotizacion }) {
  useScrollTop();
  useConsulta(planSelected, cotizacion);

  return (
    <Container>
      {/* Marcador */}
      <Marcador
        pasoUno=""
        pasoDos=""
        pasoTres=""
        pasosTerminados={true}
        titulo={
          planSelected.precio
            ? `¡${cotizacion.nombre}, contrataste tu seguro!`
            : `¡${cotizacion.nombre}, consultaste con exito!`
        }
        pasoTresTexto={planSelected.precio ? `Contratá` : `Consultá`}
      ></Marcador>
      {/* Sonido */}
      <Sound
        url={Timbre}
        autoLoad={true}
        playStatus={Sound.status.PLAYING}
        volume={80}
      />
      {/* Contrataste Container */}
      <div className="contrataste-container opacidad04">
        {/* Animación Container */}
        <div id="animacion-container">
          <span></span>
          <Animacion></Animacion>
        </div>
        {/* Texto Agradecimiento */}
        <p id="texto-contacto">
          {planSelected.precio
            ? "Un ejecutivo se contactará con vos en las próximas horas para finalizar la contratación."
            : "Un ejecutivo de ventas se contactará con vos en la brevedad para continuar con el proceso."}
          <br />
          ¡Gracias por cotizar con SeguroMotos!
        </p>
        {/* Max Width Coberturas Container */}
        <div id="max-width-coberturas-container">
          {/* Coberturas Container */}
          <div className="coberturas-container opacidad02">
            {/* Logo y Plan Container */}
            <div className="logo-plan-container">
              <img alt="Logo Seguro" src={planSelected.logo}></img>
              <p>{planSelected.plan}</p>
            </div>
            {/* Checks Container */}
            <div className="checks-container">
              {/* Map para cada check */}
              {planSelected.coberturas.map((cobertura) => {
                return (
                  <span>
                    {cobertura.activo ? (
                      <span class="material-symbols-outlined">done</span>
                    ) : (
                      <span id="check-no" class="material-symbols-outlined">
                        close
                      </span>
                    )}
                    <p>{cobertura.cobertura_info.nombre}</p>
                  </span>
                );
              })}
            </div>
            {/* Información Vehiculo */}
            <div
              className="informacion-vehiculo"
              id="informacion-vehiculo-mobile"
            >
              <p>{cotizacion.marca}</p>
              <p>{cotizacion.modelo}</p>
              <p>{cotizacion.antiguedad}</p>
              <img
                src="/pasos/contrata/escudo.svg"
                alt="Escudo de SeguroMotos"
              ></img>
            </div>
            {/* Precios Container */}
            <div className="precio-container">
              {planSelected.precio && (
                <div>
                  <p id="cuotas-bold">Cuotas de</p>
                  <p id="precio-anterior"></p>
                </div>
              )}
              <div>
                {planSelected.precio ? (
                  <p>${planSelected.precio}</p>
                ) : (
                  <p>Consulta</p>
                )}
              </div>
              {planSelected.precio && (
                <div>
                  <p>Contratando Online</p>
                </div>
              )}
            </div>
          </div>
          {/* Datos Container */}
          <div id="datos-container">
            {/* Datos Vehiculo */}
            <div className="datos-vehiculo">
              <img alt={`Ícono de ${cotizacion.producto}`} 
              
              src={
                (cotizacion.producto) === "Motocicleta"
                  ? "/iconos/moto.svg"
                  : (cotizacion.producto) ===
                    "Cuatriciclo"
                  ? "/iconos/cuatriciclo.svg"
                  : (cotizacion.producto) ===
                    "Motocicleta (Uso comercial)"
                  ? "/iconos/motoComercial.svg"
                  : (cotizacion.producto) === "Scooter"
                  ? "/iconos/scooter.svg"
                  : (cotizacion.producto) === "Ciclomotor"
                  ? "/iconos/ciclomotor.svg"
                  : "/iconos/moto.svg"
              }
                
              ></img>
              <p>{cotizacion.producto}</p>
            </div>
            {/* Información Vehiculo */}
            <div className="informacion-vehiculo">
              <p>{cotizacion.marca}</p>
              <p>{cotizacion.modelo}</p>
              <p>{cotizacion.antiguedad}</p>
              <img
                src="/pasos/contrata/escudo.svg"
                alt="Escudo de SeguroMotos"
              ></img>
            </div>
            {/* Datos Cotizacion */}
            <div id="datos-cotizacion">
              {/* Plan Seleccionado */}
              <div className="dato">
                <p>{cotizacion.provincia}</p>
                <p>{cotizacion.localidad}</p>
              </div>
              {/* Plan Seleccionado */}
              <div className="dato">
                <p>{planSelected.nombre_compania}</p>
                <p>Plan {planSelected.plan}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
