export const fieldsExcludedFromStorage = [
  "cvc",
  "vencimiento",
  "titular",
  "numeroTarjeta",
  "formaDePago",
  "cbu",
  "dniTitular",
  "bancoEmisor",
];
