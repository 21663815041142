import * as React from "react";
import { Fade } from "react-awesome-reveal";
import Container from "./styled";

export default function Marcas({ marcas }) {
  return (
    <Container marcas={marcas}>
      {/* Max Width Container */}
      {marcas == "active" && (
        <Fade
          delay={100}
          duration={700}
          triggerOnce={true}
          className="max-width-container"
        >
          {/* Titulo */}
          <h3>Seguros para todo</h3>
          {/* Texto */}
          <div className="text">
            ¡Cotizá tu seguro online!<br/>
            Accedé a nuestra amplia variedad de Seguros y sus beneficios. Descubre hoy la protección que necesitas para lo que más te importa.
          </div>
          {/* Logos Container */}
          <div>
            {/* Seguro Logo */}
            <a href="https://www.segurobici.com.ar/" target="_blank">
              <img
                alt="Logo SeguroBici"
                src="/logos/segurobici.svg"
                id="seguroBici"
              ></img>
            </a>
            {/* Seguro Logo */}
            <a href="https://www.seguromascotas.com.ar/" target="_blank">
              <img
                alt="Logo SeguroMascotas"
                src="/logos/seguromascotas.svg"
                id="seguroMascotas"
              ></img>
            </a>
            {/* Seguro Logo */}
            <a href="https://www.segurohogar.com.ar/" target="_blank">
              <img
                alt="Logo SeguroHogar"
                src="/logos/segurohogar.svg"
                id="seguroHogar"
              ></img>
            </a>
            {/* Seguro Logo */}
            <a href="https://auto.seguroweb.com.ar/" target="_blank">
              <img
                alt="Logo SeguroAuto"
                src="/logos/seguroauto.svg"
                id="seguroAuto"
              ></img>
            </a>
          </div>
        </Fade>
      )}
    </Container>
  );
}
