import axios from "axios"
import url from "../utils/url";

export const getProvincias = async () => {
    return await axios.get(`${url}/api/ubicacion/provincias`);
}

export const getLocalidades = async (provincia) => {
    return await axios.get(`${url}/api/ubicacion/localidades?provincia=${provincia}`);
}
