import React from "react";
import Emoji from "react-emojis";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";
import Container from "./styled";

export default function ReferidosHeader({ toggleReferidosHeader }) {
  return (
    <Container>
      <div id="max-width-container">
        <a id="cerrar-boton" onClick={toggleReferidosHeader}>
          <IoClose />
        </a>
        <Link to="/referidos" id="boton-referidos">
          <Emoji emoji="dizzy" size="17" />
          <Emoji emoji="fire" size="17" />
          ¡Aprovechá nuestro programa de referidos y ganá premios!
          <Emoji emoji="fire" size="17" />
          <Emoji emoji="dizzy" size="17" />
        </Link>
      </div>
    </Container>
  );
}
