import React, { Component } from "react";
import Lottie from "react-lottie";
import * as animationData from "./animacion.json";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  margin-top: 0px;
  pointer-events: none;

  div {
    height: 80px !important;
    width: 80px !important;
  }
`;

export default class AnimacionCarga extends Component {
  render() {
    const animationOptions = {
      loop: true,
      autoplay: true,
      renderer: "canvas",
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <Container className="opacidad04">
        <Lottie options={animationOptions} height={80} width={80} />
      </Container>
    );
  }
}
