import { BsArrowLeft } from "react-icons/bs";
import Container from "./styled";
import Marcador from "../marcador";
import { IoWarningOutline } from "react-icons/io5";
import { Fade } from "react-awesome-reveal";
import NumberFormat from "react-number-format";
import Plan from "./plan";
import AnimacionCarga from "../../cotizador/animacion-carga";
// Custom Hooks
import { useScrollTop } from "../../../hooks/useScrollTop";
import { useQuestion } from "../../../hooks/useQuestion";
import { useTracking } from "../../../hooks/useTracking";
import { Link } from "react-router-dom";

export default function Elegi({ nextStep, planSelected, planes, cotizacion }) {

  // Custom Hooks
  const { isQuestionOpen, handleToggle } = useQuestion()
  useScrollTop()
  useTracking()

  const ASAP_CONSULTING_CANAL = 179
  const link = cotizacion.canal === ASAP_CONSULTING_CANAL ? "/asap-consulting" : "/"

  return (
    <Container>
      {/* Marcador */}
      <Marcador
        pasoUno="hecho"
        pasoDos="haciendo"
        pasoTres=""
        pasosTerminados=""
        titulo="¡Cotizaste tu seguro con éxito!"
      ></Marcador>
      {/* Botón Atras Container */}
      <div className="boton-atras-container opacidad03">
        {/* Botón Atras */}
        <button type="button">
          <span>
            <BsArrowLeft />
          </span>
          <Link to={link}>Volvé a cotizar</Link>
        </button>
      </div>
      <p className="titulo-section">¡Genial! Cotizaste tu vehículo</p>
      {Object.keys(cotizacion).length > 0 ? 
        <Fade delay={50} duration={400} className="datos-fade-container">
          {/* Datos Container */}
          <div id="datos-container">
            {/* Datos Vehiculo */}
            <div className="datos-vehiculo">
              <img
                alt="Ícono del vehículo"
                src={
                  localStorage.getItem("vehiculo-seguromotos") == "Motocicleta"
                    ? "/iconos/moto.svg"
                    : localStorage.getItem("vehiculo-seguromotos") ==
                      "Cuatriciclo"
                    ? "/iconos/cuatriciclo.svg"
                    : localStorage.getItem("vehiculo-seguromotos") ==
                      "Motocicleta (Uso comercial)"
                    ? "/iconos/motoComercial.svg"
                    : localStorage.getItem("vehiculo-seguromotos") == "Scooter"
                    ? "/iconos/scooter.svg"
                    : localStorage.getItem("vehiculo-seguromotos") == "Ciclomotor"
                    ? "/iconos/ciclomotor.svg"
                    : "/iconos/moto.svg"
                }
              ></img>
              <p>{localStorage.getItem("vehiculo-seguromotos")}</p>
            </div>
            {/* Información Vehiculo */}
            <div className="informacion-vehiculo">
              <p>
                {cotizacion.marca.charAt(0).toUpperCase() +
                  cotizacion.marca.slice(1).toLowerCase()}
              </p>
              <p>
                {cotizacion.modelo.charAt(0).toUpperCase() +
                  cotizacion.modelo.slice(1).toLowerCase()}
              </p>
              <p>{cotizacion.antiguedad}</p>
              <img
                src="/pasos/contrata/escudo.svg"
                alt="Escudo de SeguroMotos"
              ></img>
            </div>
            {/* Datos Cotizacion */}
            <div id="datos-cotizacion">
              {/* Plan Seleccionado */}
              <div className="dato" id="dato-provincia">
                <p>{cotizacion.provincia}</p>
                <p>
                  {cotizacion.localidad.charAt(0).toUpperCase() +
                    cotizacion.localidad.slice(1).toLowerCase()}
                </p>
              </div>
            </div>
          </div>
        </Fade>
      : 
        null 
      }
      <p className="titulo-section" id="segundo-titulo">
        ¡Ahora elegí un plan a tu medida!
      </p>
      {planes.length > 0 ? (
        <div className="dropdowns-container">
          {/* Mostrar todos los planes */}
          {planes.map((plan, index) => {
            return (
              <Fade delay={50} duration={400} className="dropdown-container">
                <Plan
                  data={plan}
                  index={index}
                  active={isQuestionOpen}
                  handleToggle={handleToggle}
                  planSelected={planSelected}
                  nextStep={nextStep}
                  cotizacion={cotizacion}
                />
              </Fade>
            );
          })}
          {/* Cobertura uso laboral  */}
          {localStorage.getItem("vehiculo-seguromotos") != "motoComercial" && (
            <Fade
              delay={100}
              duration={600}
              className="cobertura-laboral-container"
            >
              <p id="cobertura-laboral">
                <span id="icono-peligro">
                  <IoWarningOutline></IoWarningOutline>
                </span>
                <br></br>
                <span id="texto-laboral">
                  Al contratar tu seguro estas declarando que no utilizas tu
                  vehículo para fines laborales y/o comerciales.
                </span>
                <br></br>
                <span id="pregunta-laboral">¿Trabajas con tu moto?</span>
                <Link to="/" onClick={() => localStorage.setItem("vehiculo-seguromotos", "Motocicleta (Uso comercial)")}>
                  Cotizá una cobertura especial para uso comercial
                </Link>
              </p>
            </Fade>
          )}
        </div>
      ) : (
        <AnimacionCarga />
      )}
    </Container>
  );
}
