import React from "react";
import Animacion from "./animacion";
import { StickyContainer, Sticky } from "react-sticky";
import { Link as Boton } from "react-router-dom";
import Container from "./styled";
// Utils
import { goToTop } from "../../../utils/scrollToTop";

export default function Confirmacion() {

  return (
    <Container>
      {/* Max Width Container */}
      <div id="max-width-container">
        <div id="formulario-container">
          <h3 className="opacidad05">¡Recibimos tu baja con éxito!</h3>
          <p className="opacidad05" id="completa">
            Recordá que dentro de las próximas 24hs hábiles nos contactaremos al
            mail otorgado para enviarte tu número de solicitud generado y
            avanzar con el proceso.
          </p>
          <Animacion></Animacion>
        </div>
        <StickyContainer>
          <Sticky>
            {({ style }) => (
              <div id="plazos-container" style={style}>
                <p>Más información</p>
                <a
                  target="_blank"
                  href="https://www.argentina.gob.ar/justicia/derechofacil/leysimple/boton-arrepentimiento#:~:text=%C2%BFQu%C3%A9%20es%20el%20bot%C3%B3n%20de,inicio%20del%20sitio%20de%20Internet."
                >
                  Argentina.gob.ar
                </a>
                <div>
                  <p>Ver más</p>
                  <Boton to="/arrepentimiento-de-compra" onClick={goToTop}>
                    Arrepentimiento de compra
                  </Boton>
                  <Boton to="/contacto">Contacto</Boton>
                </div>
              </div>
            )}
          </Sticky>
        </StickyContainer>
      </div>
    </Container>
  );
}
