import styled from "styled-components";
import { breakpoints } from "../../../constants/media";

const Container = styled.div`
  width: 100%;
  max-width: 1270px;
  background-color: var(--blanco);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  // Botón Atras Container
  .boton-atras-container {
    width: 100%;
    margin: 0px 0px -32px 0px;
    display: flex;
    justify-content: flex-start;
    z-index: 98;
  }

  // Botón Atras
  .boton-atras-container button {
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    font-size: 14px;
    background-color: var(--blanco);
    border: 0px;
    cursor: pointer;
    transition: 0.2s all !important;
  }

  // Botón Atras Icono
  .boton-atras-container button span {
    margin: 4px 10px 0px 0px;
    font-size: 20px;
    transform: translate(0, 0);
    transition: 0.2s all !important;
  }

  // Botón Atras Hover
  .boton-atras-container button:hover {
    color: var(--lila90);
  }

  // Botón Atras Hover Iconok
  .boton-atras-container button:hover > span {
    color: var(--lila90);
    transform: translate(-10px, 0);
  }

  // Max Width Coberturas Container
  #max-width-coberturas-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Titulos
  .titulo-section,
  .titulo-formulario {
    width: 100%;
    text-align: left;
    margin: 80px 0px 0px 0px;
    color: var(--gris);
    font-weight: 400;
    font-size: 14px;
  }

  .titulo-formulario {
    margin: 80px 0px 8px 0px;
  }

  // Coberturas Container
  .coberturas-container {
    width: 100%;
    height: 115px;
    margin: 32px 0px 0px 0px;
    padding: 0px 24px 0px 24px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    pointer-events: none;
    background-color: #fcfcfc;
    border-radius: 12px !important;
    box-shadow: 0px 5px 30px var(--sombraNaranja);
    border: 1px solid var(--grisClaro);
    transition: 0.2s all;
  }

  // Logo y Plan Container
  .logo-plan-container {
    width: 115px;
    height: 100%;
    padding: 0px 24px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    border-right: 1px solid var(--grisClaro);
  }

  // Logo del Seguro
  .logo-plan-container img {
    width: 100%;
    max-height: 30px;
    height: auto;
    object-fit: contain;
  }

  // Plan del Seguro
  .logo-plan-container p {
    margin: 16px 0px 0px 0px;
    font-weight: 600;
    font-size: 13px;
    text-align: center;
    color: var(--naranja100);
    line-height: 10px;
  }

  // Checks Container
  .checks-container {
    width: 100%;
    max-width: 790px;
    margin: 0px 24px 0px 24px;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
  }

  // Checks Container Icono
  .checks-container span {
    width: 95px;
    font-size: 26px;
    text-align: center;
    color: var(--verde50);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .checks-container span span {
    height: 26px !important;
  }

  // Checks Container Text
  .checks-container span p {
    height: 28px;
    margin: 0px 0px 8px 0px;
    font-size: 12px;
    font-weight: 400;
    color: var(--gris);
    line-height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    .material-symbols-outlined {
      font-variation-settings: "FILL" 0, "wght" 700, "GRAD" 0, "opsz" 40;
    }
  }

  // X Icono
  #check-no {
    font-size: 24px;
    color: var(--rojo) !important;
  }

  // Precio Container
  .precio-container {
    width: 135px;
    height: 100%;
    padding: 0px 0px 0px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    border-left: 1px solid var(--grisClaro);
  }

  // Informacion de precios
  .precio-container div {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  // Informacion de precios text
  .precio-container div p {
    margin: 0px 3px 0px 3px;
  }

  // Cuotas
  .precio-container div:nth-child(1) p {
    margin: 0px 0px 0px 0px;
    font-weight: bold;
    line-height: normal;
    font-size: 12px !important;
    text-align: center;
    color: var(--gris);

    .cuotas-naranja {
      color: var(--naranja100) !important;
    }

    #cuotas-bold {
      font-weight: 600 !important;
    }
  }

  // Precio anterior
  #precio-anterior {
    margin: 0px 0px 0px 3px;
    text-decoration: line-through !important;
  }

  // Precio y oferta
  .precio-container div:nth-child(2) p {
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: var(--naranja100);
    margin: 4px 3px 4px 3px !important;
  }

  // Contratando
  .precio-container div:nth-child(3) p {
    font-weight: 600;
    font-size: 11px;
    text-align: center;
    color: var(--lila90);
    line-height: normal;
  }

  // Datos Container
  #datos-container {
    width: 100%;
    height: 115px;
    margin: 32px 0px 0px 0px;
    padding: 0px 24px 0px 24px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    pointer-events: none;
    background-color: #fcfcfc;
    border-radius: 12px !important;
    box-shadow: 0px 5px 30px var(--sombraNaranja);
    border: 1px solid var(--grisClaro);
    transition: 0.2s all;
  }

  // Datos Vehiculo
  .datos-vehiculo {
    width: 115px;
    height: 100%;
    padding: 0px 24px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    border-right: 1px solid var(--grisClaro);
  }

  #informacion-vehiculo-mobile {
    display: none !important;
  }

  .datos-vehiculo img {
    width: 100%;
    max-height: 30px;
    height: auto;
    object-fit: contain;
  }

  .datos-vehiculo p {
    margin: 16px 0px 0px 0px;
    font-weight: 600;
    font-size: 13px;
    text-align: center;
    color: var(--naranja100);
    line-height: 10px;
  }

  .informacion-vehiculo {
    width: 200px;
    height: 100%;
    padding: 0px 24px 0px 32px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    border-right: 1px solid var(--grisClaro);
    position: relative;
    overflow: hidden;
  }

  .informacion-vehiculo p {
    width: 100%;
    margin: 0px 0px 0px 0px;
    font-weight: 600;
    font-size: 16px;
    text-align: left;
    color: var(--naranja100);
    line-height: 10px;
  }

  .informacion-vehiculo p:nth-child(2) {
    width: 100%;
    margin: 12px 0px 0px 0px;
    font-size: 14px;
    font-weight: 600;
    color: var(--lila90);
    text-align: left;
    line-height: 10px;
  }

  .informacion-vehiculo p:nth-child(3) {
    width: 100%;
    margin: 16px 0px 0px 0px;
    font-weight: 400;
    font-size: 12px;
    text-align: left;
    color: var(--gris);
    line-height: 10px;
  }

  .informacion-vehiculo img {
    position: absolute;
    width: 80px;
    top: 12;
    right: -38px;
    opacity: 0.2;
  }

  // Datos Cotización
  #datos-cotizacion {
    height: 100%;
    padding: 0px 32px 0px 32px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
  }

  // Dato
  .dato {
    width: fit-content;
    margin: 0px 32px 0px 0px;
  }

  // Dato Titulo
  .dato p {
    margin: 0px 0px 0px 0px;
    font-size: 12px;
    font-weight: 400;
    color: var(--gris);
  }

  // Dato Texto
  .dato p:nth-child(2) {
    font-size: 14px;
    font-weight: 600;
    color: var(--lila90);
  }

  // Formulario
  form {
    width: 100%;
    margin: 0px 0px 120px 0px;
    max-width: 1270px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
  }

  /* Datos Cotización Container,
  Datos Personales Container, 
  Datos de Vehículo Container 
  y Datos de Pago Container */
  .datos-personales-container,
  .datos-pago-container {
    width: 85%;
    margin: 0px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
  }

  // Datos Cotización Container
  // Datos Vehículo Container
  .datos-cotizacion-container {
    width: auto;
    display: block;
  }

  // Datos Cotización Container
  .datos-cotizacion-container {
    margin: 80px 0px 0px 0px;
  }

  // Input Container y Select Container
  .input-container,
  .select-container {
    width: 232px;
    margin: 30px 22px 0px 0px;
    position: relative;
  }

  // Select Container
  .select-container {
    height: 35px;
    margin: 30px 0px 0px 0px !important;
  }

  // Input, Select and Label
  .input-container input,
  .input-container label,
  .select-container select {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    z-index: 58 !important;
  }

  // Select Label
  .select-container label {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }

  // Inputs and Select
  .input-container input,
  .select-container select {
    width: 100%;
    height: 35px;
    padding: 0px 16px 0px 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75em;
    color: var(--gris);
    border: 1px solid rgb(152, 152, 154, 0.3);
    background-color: #fefefe;
    -webkit-appearance: none;
    box-shadow: 0;
    transition: 0.2s all;
    border-radius: 6px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  // Select
  .select-container select {
    cursor: pointer;
    height: 100%;
    z-index: 60;
  }

  // Input Error Text
  .input-container span,
  .select-container span {
    font-size: 12px !important;
    color: red;
  }

  // Input Código Postal
  #input-codigo-postal {
    width: 120px;
    margin: 30px 22px 0px 22px;
  }

  // Input Código Área
  #input-codigo-area {
    width: 90px;
  }

  // Input Teléfono y Input Vencimiento Tarjeta
  #input-telefono {
    width: 120px;
    margin: 30px 0px 0px 0px;
  }

  #input-telefono {
    margin: 30px 0px 0px 0px;
  }

  // Label
  .input-container label,
  .select-container label {
    position: absolute;
    top: 0.95em;
    left: 0;
    margin: 0px 0px 0px 16px;
    line-height: 1;
    font-size: 0.75em;
    font-weight: 400;
    color: rgb(152, 152, 154, 0.6);
    cursor: text;
    z-index: 60;
  }

  // Input and Select On Focus Label
  .input-container input:focus + label,
  .select-container select:focus + label {
    top: -0.6em;
    background-color: var(--blanco);
    padding: 0px 6px 0px 6px;
    margin: 0px 0px 0px 11px;
    font-weight: 500;
    font-size: 0.7em;
    color: var(--naranja100) !important;
    z-index: 59 !important;
  }

  // Inputs and Select Focus
  .input-container input:focus,
  .select-container select:focus {
    border: 1px solid var(--naranja100);
    outline: none;
    z-index: 59 !important;
    box-shadow: 0px 0px 5px var(--sombraNaranja);
  }

  // Label Down
  .label-down {
    z-index: 57 !important;
  }

  // Label Top
  .label-top {
    top: -0.6em !important;
    background-color: var(--blanco);
    padding: 0px 6px 0px 6px;
    margin: 0px 0px 0px 11px !important;
    font-weight: 500 !important;
    font-size: 0.7em !important;
    color: rgb(152, 152, 154, 0.8) !important;
    z-index: 59 !important;
  }

  // Input Type Number
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // Input Type Number Firefox
  input[type="number"] {
    -moz-appearance: textfield;
  }

  // Select Flecha Container
  .flecha-select-container {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 16px;
    top: calc(50% - 10px);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    background-color: var(--blanco);
    z-index: 59;
    transition: 0.2s all;
  }

  // Select Arrow
  .select-container i {
    width: 7px;
    height: 7px;
    margin: -4px 0px 0px 0px;
    display: block;
    border-left: 3px solid rgb(152, 152, 154, 0.6);
    border-bottom: 3px solid rgb(152, 152, 154, 0.6);
    transform: rotate(-45deg);
    border-radius: 10%;
    transition: 0.3s all;
  }

  .select-container select:focus ~ .flecha-select-container i {
    margin: 3px 0px 0px 0px !important;
    transform: rotate(135deg) !important;
  }

  // Select default arrow
  .select-container select::-ms-expand {
    display: none;
  }

  // Select Option
  .select-container select * {
    color: var(--black);
  }

  // Select Forma de pago
  #select-forma-de-pago {
    margin: 30px 22px 0px 0px !important;
  }

  // Boton Código Postal
  #codigo-postal-boton {
    margin: 38px 0px 0px 0px;

    span {
      color: var(--gris);
      font-size: 16px;
      opacity: 0.8;
    }
  }

  // Datos Tarjeta
  #tarjeta-datos {
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
  }

  .tarjeta-container {
    width: 100%;
    margin: 32px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
  }

  // Cobertura Laboral Container
  .cobertura-laboral-container {
    width: 100%;
    margin: 80px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
  }

  #icono-peligro {
    font-size: 22px;
    color: var(--lila90);
  }

  #texto-laboral {
    font-family: "DM Sans", sans-serif !important;
    text-align: left;
    color: var(--gris);
    font-weight: 400;
    font-size: 14px;
  }

  // Cobertura Laboral
  #cobertura-laboral {
    width: 100%;
    font-family: "DM Sans", sans-serif !important;
    margin: 0px 0px 0px 0px;
    text-align: left;
    color: var(--gris);
    font-weight: 400;
    font-size: 14px;
  }

  // Pregunta Laboral
  #pregunta-laboral {
    font-family: "DM Sans", sans-serif;
    margin: 2px 0px 0px 0px;
    color: var(--gris);
    font-weight: 400;
    font-size: 14px;
  }

  // Cobertura Laboral Botón
  #cobertura-laboral a {
    font-family: "DM Sans", sans-serif;
    margin: 2px 0px 0px 5px;
    text-decoration: none;
    color: var(--naranja100);
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    transition: 0.2s all;
  }

  // Cobertura Laboral Botón Hover
  #cobertura-laboral a:hover {
    color: var(--lila90);
  }

  // Botón Contratá Container
  #boton-contrata-container {
    width: 100%;
    margin: 50px 0px 0px 0px;
    display: flex;
    justify-content: flex-start;
  }

  // Botón Contratá
  #boton-contrata {
    width: auto;
    margin: 0px 0px 0px 0px;
    padding: 12px 24px 12px 24px;
    border-radius: 8px;
    color: var(--blanco);
    background-color: var(--naranja100);
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    font-weight: 600;
    font-size: 13px;
    border: 0;
    cursor: pointer;
    transition: 0.2s all;
    line-height: normal;
  }

  #boton-contrata img {
    height: 18px;
    margin-left: 10px;
    transition: 0.4s all;
    pointer-events: none;
  }
  // Botón Contratá Hover
  #boton-contrata:hover {
    background-color: var(--naranja40);
    box-shadow: 0px 5px 15px var(--sombraNaranja);
  }

  #boton-contrata:hover > img {
    transform: translate(10px);
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 1000px;
    }

    // Max Width Coberturas Container
    #max-width-coberturas-container {
      width: 1000px;
    }

    // Checks Container
    .checks-container {
      max-width: 602px;
    }

    // Checks Container Icono
    .checks-container span {
      width: 75.5px;
    }

    // Formulario
    form {
      max-width: 1000px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 680px;
      margin: 32px 0px 0px 0px;
    }

    #max-width-coberturas-container {
      max-width: 680px;
      justify-content: space-between;
    }

    .titulo-section {
      margin: 60px 0px 0px 0px;
    }

    // Coberturas Container
    .coberturas-container {
      justify-content: flex-start;
    }

    // Checks Container
    .checks-container {
      display: none;
    }

    #informacion-vehiculo-mobile {
      width: 274px;
      display: flex !important;
    }

    // Precio Container
    .precio-container {
      border-left: 0px;
    }

    // Datos Container
    #datos-container {
      display: none;
    }

    // Formulario
    form {
      max-width: 680px;
    }

    // Input Container y Select Container
    .input-container,
    .select-container {
      width: 212px;
    }

    #input-cuit-dni,
    #input-numero-tarjeta,
    #input-vencimiento {
      margin: 30px 0px 0px 0px;
    }

    #input-telefono,
    #input-codigo-postal {
      width: 101px;
    }

    .datos-personales-container,
    #tarjeta-datos {
      width: 100%;
    }

    // Datos Tarjeta
    .datos-tarjeta {
      width: 100%;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 480px;
    }

    // Max Width Coberturas Container
    #max-width-coberturas-container {
      max-width: 480px;
    }

    // Coberturas Container
    .coberturas-container {
      width: 299px;
    }

    #informacion-vehiculo-mobile {
      display: none !important;
    }

    // Formulario
    form {
      max-width: 480px;
    }

    #input-cuit-dni,
    #input-numero-tarjeta,
    #input-vencimiento {
      margin: 30px 22px 0px 0px;
    }

    #input-apellido,
    #input-email {
      margin: 30px 0px 0px 0px;
    }

    // Pregunta Laboral
    #pregunta-laboral {
      width: 100%;
      margin: 0px 0px 10px 0px;
      display: block;
    }

    // Cobertura Laboral Botón
    #cobertura-laboral a {
      margin: 0px 0px 0px 0px;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 380px;
    }

    // Max Width Coberturas Container
    #max-width-coberturas-container {
      max-width: 380px;
    }

    // Formulario
    form {
      max-width: 380px;
    }

    // Datos Cotización
    #datos-cotizacion {
      width: 80%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
    }

    // Input Container y Select Container
    .input-container,
    .select-container {
      width: 179px !important;
    }

    #input-banco-emisor,
    #input-titular,
    #input-vencimiento {
      margin: 30px 0px 0px 0px;
    }

    #input-codigo-postal {
      margin: 30px 22px 0px 0px;
    }
  }

  // 450px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile450}) {
    // Botón Atras Container
    .boton-atras-container {
      max-width: 85%;
    }

    // Titulos
    .titulo-section,
    .titulo-formulario {
      margin: 50px 0px 0px 0px;
    }

    // Max Width Coberturas Container
    #max-width-coberturas-container {
      max-width: 85%;
      justify-content: center;
    }

    .coberturas-container {
      width: 100%;
      padding: 0px 0px 0px 0px;
      height: min-content;
    }

    // Logo y Plan Container
    .logo-plan-container,
    .precio-container {
      width: 100%;
      height: auto;
      margin: 0px 0px 0px 0px;
      padding: 24px 0px 24px 0px;
      border-right: 0px;
    }

    .logo-plan-container {
      border-bottom: 1px solid var(--grisClaro);
    }

    // Formulario
    form {
      max-width: 85%;
    }

    // Input Container y Select Container, Input CUIT DNI, Input Código Área, Input Vencimiento, Input Telefono
    .input-container,
    .select-container {
      width: 100% !important;
      max-width: 100% !important;
      margin: 30px 0px 0px 0px !important;
    }

    #select-forma-de-pago {
      margin: 30px 0px 0px 0px !important;
    }

    #codigo-postal-boton {
      display: none;
    }

    // Cobertura Laboral
    #cobertura-laboral {
      max-width: 100%;
    }
  }

  // 350px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile350}) {
    // Input Container y Select Container, Input CUIT DNI, Input Código Área, Input Vencimiento, Input Telefono
    .input-container,
    .select-container,
    #input-cuit-dni,
    #input-codigo-area,
    #input-vencimiento,
    #input-telefono {
      width: 100%;
      max-width: 100%;
      margin: 30px 0px 0px 0px;
    }
  }
`;

export default Container;
