import styled from "styled-components";
import { breakpoints } from "../../../../constants/media";

const Container = styled.div`
  width: 100%;
  margin: 0px 0px 0px 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  // Dropdown Abierto y Dropdown Cerrado
  .dropdown-abierto,
  .dropdown-cerrado {
    width: 100%;
    height: 115px;
    margin: 0px 0px 0px 0px;
    padding: 0px 24px 0px 24px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    pointer-events: none;
    background-color: #fcfcfc;
    border: 0;
    border-radius: 12px !important;
    transition: 0.2s all;
  }

  // Mejor Precio y Recomendado Container
  .mejor-precio,
  .recomendado {
    width: auto;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: var(--grisClaro2);
    border-radius: 6px 6px 0px 0px;
    position: absolute;
    top: -20px;
    left: 25px;
  }

  // Mejor Precio Container Background Effect
  .shimmerPrecio {
    background-image: linear-gradient(
      45deg,
      var(--grisClaro2) 0%,
      var(--grisClaro2) 40%,
      #b7b7b7 50%,
      #b7b7b7 55%,
      var(--grisClaro2) 65%,
      var(--grisClaro2) 100%
    );
    background-size: 300%;
    animation: 2500ms ease 0s infinite normal none running shimmer;
  }

  // Recomendado Container Background Effect
  .shimmerRecomendado {
    background-image: linear-gradient(
      45deg,
      #daa520 0%,
      #daa520 40%,
      #ffc600 50%,
      #ffc600 55%,
      #daa520 65%,
      #daa520 100%
    );
    background-size: 300%;
    animation: 2500ms ease 0s infinite normal none running shimmer;
  }

  // Apto Competencia Container Background Animation
  @keyframes shimmer {
    0% {
      background-position: 100% 100%;
    }
    100% {
      background-position: 0px 0px;
    }
  }

  // Mejor Precio y Recomendado Texto
  .mejor-precio p,
  .recomendado p {
    margin: 0px 0px 0px 0px;
    padding: 5px 15px 4px 15px;
    font-size: 11px;
    text-align: center;
    color: var(--blanco);
    letter-spacing: 0.5px;
  }

  // Mejor Precio y Recomendado Icon
  .mejor-precio p span,
  .recomendado p span {
    margin: 0px 5px 0px 0px;
    font-size: 10px;
  }

  // Logo y Plan Container
  .logo-plan-container {
    width: 115px;
    height: 100%;
    padding: 0px 24px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    border-right: 1px solid var(--grisClaro);
  }

  // Logo del Seguro
  .logo-plan-container img {
    width: 100%;
    height: ${(props) =>
      props.seguro == "ATM"
        ? "42px"
        : props.seguro == "Rio Uruguay Seguros"
        ? "40px"
        : props.seguro == "Colón Seguros"
        ? "38px"
        : props.seguro == "Federación Patronal Seguros"
        ? "40px"
        : "40px"};
    object-fit: contain;
  }

  // Plan del Seguro
  .logo-plan-container p {
    margin: 12px 0px 0px 0px;
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    color: var(--naranja100);
    line-height: 16px;
  }

  // Checks Container
  .checks-container {
    width: 694px;
    margin: 0px 24px 0px 24px;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
  }

  // Checks Container Icono
  .checks-container span {
    width: 139px;
    font-size: 26px;
    text-align: center;
    color: var(--verde50);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
  }

  .checks-container span span {
    height: 26px !important;
  }

  // Checks Container Text
  .checks-container span p {
    height: 28px;
    margin: 0px 0px 8px 0px;
    font-size: 12px;
    font-weight: 400;
    color: var(--gris);
    line-height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    .material-symbols-outlined {
      font-variation-settings: "FILL" 0, "wght" 700, "GRAD" 0, "opsz" 40;
    }
  }

  // X Icono
  #check-no {
    font-size: 24px;
    color: var(--rojo) !important;
  }

  // Precio Container
  .precio-container {
    width: 135px;
    height: 115px;
    padding: 0px 24px 0px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    border-left: 1px solid var(--grisClaro);
  }

  // Informacion de precios
  .precio-container div {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  // Informacion de precios text
  .precio-container div p {
    margin: 0px 3px 0px 3px;
  }

  // Cuotas
  .precio-container div:nth-child(1) p {
    margin: 0px 0px 0px 0px;
    font-weight: bold;
    line-height: normal;
    font-size: 12px !important;
    text-align: center;
    color: var(--gris);

    .cuotas-naranja {
      color: var(--naranja100) !important;
    }

    #cuotas-bold {
      font-weight: 600 !important;
    }
  }

  // Precio anterior
  #precio-anterior {
    margin: 0px 0px 0px 3px;
    text-decoration: line-through !important;
  }

  // Precio y oferta
  .precio-container div:nth-child(2) p {
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: var(--naranja100);
    margin: 4px 3px 4px 3px !important;
  }

  // Contratando
  .precio-container div:nth-child(3) p {
    font-weight: 600;
    font-size: 11px;
    text-align: center;
    color: var(--lila90);
    line-height: normal;
  }

  // Precion Vacio
  #precio-vacio {
    font-weight: 200;
    font-size: 14px;
    text-align: center;
    color: var(--gris);
  }

  // Botones Container
  .botones-container {
    width: 115px;
    height: 100%;
    padding: 0px 8px 0px 32px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    border-left: 1px solid var(--grisClaro);
  }

  #contrata-boton {
    width: 100%;
    height: auto;
    padding: 8px 24px 8px 24px;
    background-color: var(--naranja100);
    color: var(--blanco);
    text-align: center;
    font-weight: 600;
    font-size: 13px;
    border: 0;
    border-radius: 8px;
    pointer-events: all;
    cursor: pointer;
    transition: 0.2s all;
  }

  #contrata-boton.btn-big {
    padding: 8px;
    white-space: nowrap;
  }

  #contrata-boton:hover {
    background-color: var(--naranja40);
  }

  #ver-mas-boton {
    border: 0px;
    cursor: pointer;
    margin: 12px 0px 0px 0px;
    background-color: transparent;
    font-size: 11px;
    text-align: center;
    color: var(--gris);
    pointer-events: all;
    transition: 0.2s all;
    font-weight: 400;
  }

  #ver-mas-boton:hover {
    color: var(--negro);
  }

  // Buscando Container
  #buscando-container {
    width: calc(100% - 164px);
    height: 115px;
    margin: 0px 0px 0px 24px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  // Animación Loading
  #animacion-loading {
    width: 80px !important;
    height: 80px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
  }

  // Text Container
  .children-container {
    width: 100%;
    border-top: 1px solid var(--grisClaro);
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    align-content: center;
  }

  // Tiempo de transicion cuando se abre y se cierra
  .ReactCollapse--collapse {
    transition: height 550ms;
  }

  // 1710px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1710}) {
    // Dropdown Abierto y Dropdown Cerrado
    .dropdown-abierto,
    .dropdown-cerrado {
      padding: 0px 16px 0px 12px;
    }

    // Logo y Plan Container
    .logo-plan-container {
      padding: 0px 12px 0px 0px;
    }

    // Logo del Seguro
    .logo-plan-container img {
      width: 85%;
      height: ${(props) =>
        props.seguro == "ATM"
          ? "39px"
          : props.seguro == "Rio Uruguay Seguros"
          ? "40px"
          : props.seguro == "Colón Seguros"
          ? "38px"
          : props.seguro == "Federación Patronal Seguros"
          ? "40px"
          : "40px"};
    }

    // Checks Container
    .checks-container {
      max-width: 520px;
      margin: 0px 16px 0px 16px;
    }

    // Checks Container Icono
    .checks-container span {
      width: 104.2px;
    }

    // Precio Container
    .precio-container {
      padding: 0px 8px 0px 8px;
    }

    // Botones Container
    .botones-container {
      width: 115px;
      height: 100%;
      padding: 0px 0px 0px 24px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      border-left: 1px solid var(--grisClaro);
    }

    // Buscando Container
    #buscando-container {
      width: calc(100% - 140px);
      margin: 0px 0px 0px 0px;
    }
  }

  // 1200px Desktop ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.desktop1200}) {
    .dropdown-abierto,
    .dropdown-cerrado {
      max-width: 680px !important;
    }

    // Logo y Plan Container
    .logo-plan-container {
      padding: 0px 16px 0px 0px;
    }

    // Checks Container
    .checks-container {
      max-width: 174px;
    }

    // Checks
    .check {
      display: none !important;
    }

    // Checks principales
    .check:first-child,
    .check:nth-child(4) {
      width: 87.2px;
      display: flex !important;
    }

    // Precio Container
    .precio-container {
      padding: 0px 16px 0px 16px;
    }

    // Botones Container
    .botones-container {
      padding: 0px 4px 0px 24px;
    }

    // Buscando Container
    #buscando-container {
      width: calc(100% - 148px);
      margin: 0px 0px 0px 8px;
    }
  }

  // 850px Tablet ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.tablet850}) {
    // Checks Container
    .checks-container {
      display: none;
    }

    // Precio Container
    .precio-container {
      width: 142px;
      border: 0;
    }
  }

  // 651px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile651}) {
    .dropdown-abierto,
    .dropdown-cerrado {
      max-width: 350px !important;
    }

    // Logo y Plan Container
    .logo-plan-container {
      width: 137px;
    }

    // Precio Container
    .precio-container {
      display: none;
    }

    // Botones Container
    .botones-container {
      width: 138px;
      border-left: 0px;
    }

    // Buscando Container
    #buscando-container {
      width: calc(100% - 170px);
      margin: 0px 0px 0px 16px;
    }
  }

  // 385px Mobile ------------------------------------------------------------------------------------
  @media (max-width: ${breakpoints.mobile385}) {
    .dropdown-abierto,
    .dropdown-cerrado {
      max-width: 100%;
      height: 184px;
      padding: 0px;
    }

    // Logo y Plan Container
    .logo-plan-container {
      width: 100%;
      height: auto;
      padding: 0px 0px 24px 0px;
      border: 0px;
      border-bottom: 1px solid var(--grisClaro);
    }

    // Logo del Seguro
    .logo-plan-container img {
      width: 100%;
      height: ${(props) =>
        props.seguro == "ATM"
          ? "42px"
          : props.seguro == "Rio Uruguay Seguros"
          ? "36px"
          : props.seguro == "Colón Seguros"
          ? "27px"
          : props.seguro == "Federación Patronal Seguros"
          ? "25px"
          : "30px"};
    }

    // Botones Container
    .botones-container {
      width: 100%;
      height: auto;
      padding: 24px 0px 0px 0px;
    }

    #contrata-boton {
      width: 50%;
    }

    #ver-mas-boton {
      width: 51%;
    }

    // Text Container
    .children-container {
      margin: 24px 0px 0px 0px;
    }

    // Buscando Container
    #buscando-container {
      width: 100%;
      margin: 0px 0px 0px 0px;
    }
  }
`;

export default Container;
