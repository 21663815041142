import React from "react";
import Container from "./styled";
import Animacion from "./animacion";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";

export default function Referidos() {
  return (
    <Container>
      <Fade delay={100} duration={800} className="fade-container">
        <div id="max-width-container">
          {/* Titulo */}
          <h3>
            Aprovechá nuestro <span>programa de referidos</span> y ganá
          </h3>
          <h4>¡Ganás por cada contacto que refieras y contrate un seguro con la empresa!</h4>
          {/* Información Container */}
          <div id="informacion-referidos-container">
            <div id="imagen-container">
              <Animacion></Animacion>
            </div>
            <div id="texto-container">
              <h4 ><b style={{fontSize:"18px"}}>Referí sin límites.</b> Por cada persona que refieras y contrate un seguro con SeguroMotos o SeguroWeb, sumas:</h4>
              <a href="https://www.segurobici.com.ar/" target="blank" alt="seuguroMoto" id="texto-cliente">
              <div >
                <p>$2.000 por cada referido que contrate un seguro de bici.</p>
              </div>
              </a>
            
              <div id="texto-cliente">
                <p>$4.000 por cada referido que contrate un seguro de moto.</p>
              </div>
            <a href="https://auto.seguroweb.com.ar/" target="blank" alt="seguroAuto" id="texto-no-cliente">
              <div >
                <p>$7.000 por cada referido que contrate un seguro de auto.</p>
              </div>
            </a>

            <a href="https://camiones.seguroweb.com.ar/" target="blank" alt="seguroAuto" id="texto-no-cliente">
              <div>
                <p>$7.000 por cada referido que contrate un seguro de camión con cobertura de Responsabilidad Civil.</p>
              </div>
            </a>

            <a href="https://camiones.seguroweb.com.ar/" target="blank" alt="seguroAuto" id="texto-no-cliente">
              <div>
                <p>$14.000 por cada referido que contrate un seguro de camión con coberturas más amplias que Responsabilidad Civil.</p>
              </div>
            </a>

            </div>
          </div>
          <a href="https://api.whatsapp.com/send?phone=5491162255399&text=Hola%2C%20estoy%20interesado%20en%20contratar%20un%20seguro" id="referir-boton">
            ¡Quiero referir!
          </a>
        </div>
      </Fade>
    </Container>
  );
}
